import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import TooltipV2 from '../publicationShow/tooltip/tooltipv2';
import SampleTooltipContent from '../sampleTooltipContent';

type Props = { isLocked: boolean } & React.PropsWithoutRef<LinkProps<any> & React.RefAttributes<HTMLAnchorElement>>;

const LockedLink: FC<Props> = ({ isLocked, children, ...linkProps }) => {
  if (isLocked) {
    return (
      <TooltipV2 tooltipContent={<SampleTooltipContent />} reverse>
        {children}
      </TooltipV2>
    );
  }

  return <Link {...linkProps}>{children}</Link>;
};

export default LockedLink;
