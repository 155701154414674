import React, { FC } from 'react';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';
import { Button } from '@pluto_network/pluto-design-elements';
import responsive from 'styles/responsive';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { signOut } from 'api/auth';
import { DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY } from 'components/demoGuideModal';
import { useTranslation } from 'react-i18next';
import SelectLocaleButton from 'components/selectLocaleButton';
import { useMutation, useQueryClient } from 'react-query';
import gtm, { EventName } from '../../helpers/gtm';
import palette from '../../styles/palette';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'components/icons/logo';

const HeaderLayout = styled.header`
  z-index: 2;
  position: fixed;
  padding: 11px 36px;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (max-width: ${responsive.tablet_width}) {
    padding: 12px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const RightBox = styled.div`
  display: flex;
`;

type Props = {
  hideButtons?: boolean;
  onClickViewDemoButton?: () => void;
};

const RightButtons: FC<{
  onClickViewDemoButton?: () => void;
}> = ({ onClickViewDemoButton }) => {
  const location = useLocation();
  const { user, is_demo } = useCurrentUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation(['header', 'general']);
  const { mutateAsync: logout } = useMutation('/auth/login', signOut, {
    onSuccess: () => {
      Cookies.remove(DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY);
      queryClient.setQueryData('/auth/login', null);
      gtm.event(is_demo ? EventName.LOGOUT_DEMO : EventName.LOGOUT);
      alert('Logout succeeded. Good bye.');
    },
  });

  const showLoginButton = location.pathname !== '/' && !onClickViewDemoButton;

  if (!user) {
    return (
      <RightBox>
        {onClickViewDemoButton && (
          <button
            className="px-3 py-2 rounded-md text-gray-600 border border-solid border-gray-300 hover:bg-gray-50 ml-2"
            aria-label="Xenure view demo button"
            onClick={onClickViewDemoButton}
          >
            <span>{t('start_demo')}</span>
          </button>
        )}
        {showLoginButton && (
          <Button elementType="link" to="/" variant="outlined">
            Log In
          </Button>
        )}
        <Link
          className="gotcha px-3 py-2 rounded-md bg-blue-500 hover:bg-blue-400 text-white ml-2"
          to="/sign-up"
          aria-label="sign up button"
        >
          <span style={{ textTransform: 'capitalize' }}>{t('general:sign_up')}</span>
        </Link>
        <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'stretch' }}>
          <SelectLocaleButton
            btnStyle={{
              textAlign: 'left',
              background: 'none',
              border: `1px solid ${palette.gray300}`,
              padding: '8px 12px',
              fontSize: '14px',
              color: palette.black0,
            }}
          />
        </div>
      </RightBox>
    );
  }

  return (
    <RightBox>
      <Button
        color="gray"
        size="large"
        type="submit"
        elementType="button"
        aria-label="Xenure sign out button"
        onClick={async () => await logout()}
      >
        <span>{is_demo ? t('end_demo') : t('general:logout')}</span>
      </Button>
    </RightBox>
  );
};

const Header: React.FC<Props> = ({ onClickViewDemoButton, hideButtons }) => {
  return (
    <HeaderLayout className="bg-white">
      <HeaderContainer>
        <Link to="/">
          <Logo style={{ width: '120px', height: '30px' }} />
        </Link>
        {!hideButtons && <RightButtons onClickViewDemoButton={onClickViewDemoButton} />}
      </HeaderContainer>
    </HeaderLayout>
  );
};

export default Header;
