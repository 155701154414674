import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@pluto_network/pluto-design-elements';
import palette from 'styles/palette';
import responsive from 'styles/responsive';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';

const TryBannerContainer = styled.section`
  background-color: ${palette.black0};
`;

const TryBannerWrapper = styled.div`
  max-width: 940px;
  padding: 120px 0;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${responsive.tablet_width}) {
    padding: 80px 24px;
  }
`;

const TryBannerTitle = styled.div`
  color: ${palette.gray30};
  font-weight: 700;
  font-size: 44px;
  line-height: 1.3;
  text-transform: capitalize;

  @media (max-width: ${responsive.tablet_width}) {
    font-size: 32px;
  }
`;

const ActionButton = styled(Button)`
  margin-top: 16px;

  &:hover {
    color: white;
  }
`;

const TryBannerSection: React.FC = () => {
  const { t } = useTranslation(['about_page', 'general']);
  const { user } = useCurrentUser();

  if (user && !user.is_confirmed) return null;

  return (
    <TryBannerContainer>
      <TryBannerWrapper>
        <TryBannerTitle>{t('try_banner_title')}</TryBannerTitle>
        {!user ? (
          <ActionButton
            className="gotcha"
            color="blue"
            size="large"
            type="submit"
            elementType="link"
            to="/sign-up"
            aria-label="Xenure sign up button"
          >
            <span style={{ textTransform: 'capitalize'}}>{t('general:sign_up')}</span>
          </ActionButton>
        ) : (
          <ActionButton
            color="blue"
            size="large"
            type="submit"
            elementType="link"
            to="/overview"
            aria-label="Xenure start button"
          >
            <span style={{ textTransform: 'capitalize'}}>{t('general:start')}</span>
          </ActionButton>
        )}
      </TryBannerWrapper>
    </TryBannerContainer>
  );
};

export default TryBannerSection;
