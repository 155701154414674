import { atom } from 'recoil';
import { Report } from '../model/report';

interface ReportState {
  report: Report | null;
}

export const reportState = atom<ReportState>({
  key: 'reportState',
  default: { report: null },
});
