import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { signOut } from '../../api/auth';
import { DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY } from '../demoGuideModal';
import RoundButton from '../roundButton';
import palette from '../../styles/palette';
import gtm, { EventName } from '../../helpers/gtm';

const DemoContent: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['top_banner', 'general']);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation('/auth/login', signOut, {
    onSuccess: () => {
      Cookies.remove(DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY);
      queryClient.setQueryData('/auth/login', null);
      history.push('/');
      gtm.event(EventName.LOGOUT_DEMO);
    },
  });

  return (
    <>
      <span
        style={{ fontSize: '14px', color: palette.gray800 }}
        dangerouslySetInnerHTML={{ __html: t('currently_using_demo') }}
      />
      <RoundButton
        className="gotcha"
        style={{ backgroundColor: palette.main_blue0, color: 'white', fontSize: '12px', marginLeft: '24px' }}
        onClick={() => history.push('/sign-up')}
      >
        <i className="dripicons-user mr-2" />
        <span>{t('general:sign_up')}</span>
      </RoundButton>
      <RoundButton
        style={{ backgroundColor: palette.gray300, color: palette.main_blue0, fontSize: '12px', marginLeft: '16px' }}
        onClick={async () => {
          await mutateAsync();
        }}
      >
        <i className="dripicons-exit mr-2" />
        <span>{t('exit_demo')}</span>
      </RoundButton>
    </>
  );
};

export default DemoContent;
