import React, { FC, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import SignedHeader from '../../signedHeader';
import Sidebar from '../../sidebar';
import Fallback from '../../fallback';
import MiniFooter from '../../miniFooter';

const SideBarLayout: FC = ({ children }) => {
  return (
    <>
      {isMobile && (
        <Helmet>
          <meta name="viewport" content="width=1200px" />
        </Helmet>
      )}
      <SignedHeader />
      <Sidebar />
      <div className="ml-72 pb-8">
        <Suspense fallback={<Fallback />}>{children}</Suspense>
      </div>
      <MiniFooter />
    </>
  );
};

export default SideBarLayout;
