import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikErrors } from 'formik';
import FormikInput from 'components/common/formikInput';
import { Button } from '@pluto_network/pluto-design-elements';
import { useCurrentUser } from 'hooks/useCurrentUser';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { sendFeedback } from 'api/feedback';

interface FormValues {
  email: string;
  content: string;
}

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #666d7c;
  text-align: left;
`;

/** @deprecated */
const FloatingFeedbackForm: FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const { t } = useTranslation('feedback_dropdown');
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useCurrentUser();

  function validateForm(formValues: FormValues) {
    const errors: FormikErrors<FormValues> = {};

    if (!formValues.email) {
      errors.email = t('email_error_message');
    }

    if (!formValues.content) {
      errors.content = t('content_error_message');
    }

    return errors;
  }

  async function handleSubmitFeedbackForm(values: FormValues) {
    setIsLoading(true);
    try {
      await sendFeedback({
        email: values.email,
        content: values.content,
      });

      alert(t('after_send_message'));
      onSubmit();
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  return (
    <Formik<FormValues>
      initialValues={{ email: user?.email || '', content: '' }}
      onSubmit={handleSubmitFeedbackForm}
      validate={validateForm}
      validateOnChange={false}
    >
      <Form>
        <Title>Direct Message</Title>
        <Field
          name="email"
          type="email"
          component={FormikInput}
          placeholder="Email"
          labelText="Email"
          style={{ marginBottom: '16px' }}
        />
        <Field
          name="content"
          component={FormikInput}
          placeholder={t('content_form_placeholder')}
          labelText="Content"
          rows={3}
          multiline
        />
        <Button
          elementType="button"
          type="submit"
          aria-label="Send feedback button"
          isLoading={isLoading}
          disabled={isLoading}
          style={{ marginTop: '16px' }}
          fullWidth
        >
          <span>{isLoading ? 'Sending...' : 'SEND'}</span>
        </Button>
      </Form>
    </Formik>
  );
};

export default FloatingFeedbackForm;
