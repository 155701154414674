import styled from '@emotion/styled';
import { CategoryJournalRank } from 'model/categoryRank';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, Column, UseSortByColumnProps, HeaderGroup } from 'react-table';
import palette from 'styles/palette';

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border: 1px solid ${palette.gray800};
  background-color: white;

  border-radius: 4px;
  border-collapse: separate;

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid ${palette.gray800};
    border-right: 1px solid ${palette.gray800};
    text-align: center;

    :last-child {
      border-right: 0;
    }
  }
`;

const Tr = styled.tr`
  &:last-child {
    td {
      border-bottom: 0;
    }
  }
`;

type Field = Omit<CategoryJournalRank, 'year'>;

type SortableHeaderGroup = HeaderGroup<Field> & UseSortByColumnProps<Field>;

interface Props {
  categoryJournalRanks: CategoryJournalRank[];
}

function formatChange(change: number | undefined) {
  if (!change) return 0;

  let number = change.toString();
  if (change % 1 !== 0) {
    number = change.toFixed(2);
  }

  if (change > 0) return `+${number}`;
  return number;
}

function getTextClassName(change: string, reverse?: boolean) {
  if (!change.includes('+') && !change.includes('-')) return '';
  if (change.includes('+')) return reverse ? 'text-danger' : 'text-success';
  return reverse ? 'text-success' : 'text-danger';
}

function getIconClassName(change: string) {
  if (!change.includes('+') && !change.includes('-')) return '';
  if (change.includes('+')) return 'mdi mdi-arrow-up-bold text-green-500';
  return 'mdi mdi-arrow-down-bold text-red-500';
}

const DisplayCell: FC<{ value: string; reverseColor?: boolean }> = ({ value, reverseColor }) => {
  if (!value) return null;
  if (!value.includes('(+') && !value.includes('(-')) return <>{String(value)}</>;

  const splitValueChange = value.split(' ');

  return (
    <span>
      <span>{splitValueChange[0]}</span>
      <span className={getTextClassName(splitValueChange[1], reverseColor)}>
        <i className={getIconClassName(splitValueChange[1])} />
        <span>{splitValueChange[1]}</span>
      </span>
    </span>
  );
};

const JournalRankTable: FC<Props> = ({ categoryJournalRanks }) => {
  const columns: Column<Field>[] = useMemo(() => {
    return [
      {
        Header: 'Journal Rank',
        accessor: (row) => `${row.rank} (${formatChange(row.rank_change)})`,
        Cell: ({ value }: any) => <DisplayCell value={value} />,
      },
      { Header: 'Journal', accessor: (row) => row.journal!.title },
      {
        Header: 'Impact Factor(IF)',
        accessor: (row) => row.impact_factor?.toFixed(2),

        sortDescFirst: true,
      },
      {
        Header: 'Best IF Percentage',
        accessor: (row) =>
          `${row.if_top_percentile?.toFixed(2) + '%' || ''} (${formatChange(row.if_top_percentile_change)})`,
        Cell: ({ value }: any) => <DisplayCell value={value} reverseColor />,
      },
      {
        Header: 'Best MRN IF',
        accessor: (row) => `${row.top_mrn_if?.toFixed(2) || ''} (${formatChange(row.top_mrn_if_change)})`,
        Cell: ({ value }: any) => <DisplayCell value={value} />,
        sortDescFirst: true,
      },
    ];
  }, []);

  const tableInstance = useTable<Field>({ columns, data: categoryJournalRanks }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const header = column as SortableHeaderGroup;
                return (
                  <th {...header.getHeaderProps(header.getSortByToggleProps())}>
                    {header.render('Header')}
                    <span>{header.isSorted ? (header.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                );
              })}
            </Tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'Journal') {
                    return (
                      <td {...cell.getCellProps()} style={{ color: palette.main_blue0 }}>
                        <Link to={`/journals/${cell.row.original.journal?.id}`}>{cell.render('Cell')}</Link>
                      </td>
                    );
                  }
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default JournalRankTable;
