import { User } from 'model/user';
import gtm from '../helpers/gtm';
import { SAMPLE_USER_TOKEN_KEY } from 'constants/sessionStorage';
import { Token } from '../model/token';
import Fetcher from './fetcher';

type SignInParams = {
  email: string;
  password: string;
};

export interface SignInResult {
  is_logged_in: boolean;
  is_admin: boolean;
  is_demo: boolean;
  is_token_expired: boolean;
  login_method: 'TOKEN' | 'PASSWORD';
  token: Token;
  user: User;
}

interface SignUpParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  affiliationId: string;
  affiliationName: string;
  publicationList: string;
  referrer?: string;
}

export async function createSampleUser(params: SignUpParams) {
  const res = await Fetcher.instance.post<{ data: User }>('/users/sample', {
    affiliation_id: params.affiliationId,
    affiliation_name: params.affiliationName,
    first_name: params.firstName,
    last_name: params.lastName,
    email: params.email,
    password: params.password,
    publication_source: params.publicationList,
  });

  return res.data.data;
}

export async function createUser(params: SignUpParams) {
  const res = await Fetcher.instance.post<{ data: User }>('/users', {
    affiliation_id: params.affiliationId,
    affiliation_name: params.affiliationName,
    email: params.email,
    first_name: params.firstName,
    last_name: params.lastName,
    password: params.password,
    publication_source: params.publicationList,
    referrer: params.referrer || null,
  });

  return res.data.data;
}

export async function checkLoggedIn() {
  const res = await Fetcher.instance.get<{ data: SignInResult }>('/auth/check');
  const checkLoggedInData = res.data.data;

  gtm.setUserProperty(checkLoggedInData?.user);
  return checkLoggedInData;
}

export async function signIn(params: SignInParams) {
  const res = await Fetcher.instance.post('/auth/login', { email: params.email, password: params.password });
  const signInData: SignInResult = res.data.data;
  Fetcher.setAccessToken(res.data.data.token);

  gtm.setUserProperty(signInData?.user);
  return signInData;
}

export async function signOut() {
  const res = await Fetcher.instance.post<{ data: SignInResult }>('/auth/logout');

  sessionStorage.removeItem(SAMPLE_USER_TOKEN_KEY);
  delete Fetcher.instance.defaults.headers.common['X-Sample-Token'];
  Fetcher.removeAccessToken();

  return res.data.data;
}

export async function updatePassword(password: string) {
  const res = await Fetcher.instance.put('/users/me/password', { password });
  return res.data.data;
}

export async function signInDemo() {
  const res = await Fetcher.instance.post<{ data: SignInResult }>('/auth/login/demo');
  Fetcher.setAccessToken(res.data.data.token);
  return res.data.data;
}

export async function getPublicationSource() {
  const res = await Fetcher.instance.get('/users/me/publication-source');
  return res.data.data;
}

export async function updatePublicationSource(source: string) {
  const res = await Fetcher.instance.put('/users/me/publication-source', { source });
  return res.data.data;
}

export async function requestResetPasswordEmail(email: string) {
  await Fetcher.instance.post('/auth/password-token', { email });
}

export async function resetPassword({ token, password }: { token: string; password: string }) {
  await Fetcher.instance.post('/auth/reset-password', { token, password });
}
