import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import CategorySelector from 'components/categorySelector';
import JournalRankTable from 'components/journalRankTable';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { lastJournalRankSelectedYear } from 'atom/lastJournalRankSelectedYear';
import { SAVED_JOURNAL_RANK_CATEGORIES_KEY } from 'constants/category';
import { RecentSelectedCategory } from 'model/category';
import { Button } from '@pluto_network/pluto-design-elements';
import { parse } from 'json2csv';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { getCategory, getJournalRankInCategory } from '../../api/category';
import { AxiosError } from 'axios';

const PageWrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const JournalRankShow: FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>();
  const params = useParams<{ category_id: string }>();
  const [lastJournalRankSelectedYearState, setLastJournalRankSelectedYearState] =
    useRecoilState(lastJournalRankSelectedYear);

  const { data: currentCategory } = useQuery(
    `/categories/${params.category_id}`,
    () => getCategory(params.category_id),
    { enabled: !!params.category_id, retry: false }
  );

  const { data: categoryRank } = useQuery(
    `/categories/${params.category_id}/journal-ranks`,
    () => getJournalRankInCategory(params.category_id),
    { enabled: !!params.category_id, retry: false }
  );

  useEffect(() => {
    if (!currentCategory) return;

    const rawSavedCategories = localStorage.getItem(SAVED_JOURNAL_RANK_CATEGORIES_KEY);
    const savedCategories: RecentSelectedCategory[] = rawSavedCategories ? JSON.parse(rawSavedCategories) : [];

    const index = savedCategories.findIndex((c) => c.id === currentCategory.id);
    if (index > -1) {
      const oldOneRemovedCategories = [...savedCategories.slice(0, index), ...savedCategories.slice(index + 1)];
      localStorage.setItem(
        SAVED_JOURNAL_RANK_CATEGORIES_KEY,
        JSON.stringify([currentCategory, ...oldOneRemovedCategories])
      );
    } else {
      localStorage.setItem(SAVED_JOURNAL_RANK_CATEGORIES_KEY, JSON.stringify([currentCategory, ...savedCategories]));
    }
  }, [currentCategory]);

  useEffect(() => {
    if (!categoryRank) return;
    const lastSelectedYear = lastJournalRankSelectedYearState.get(params.category_id);
    if (lastSelectedYear) {
      setSelectedYear(lastSelectedYear);
    } else {
      setSelectedYear(categoryRank[categoryRank.length - 1]?.year);
    }
  }, [categoryRank, lastJournalRankSelectedYearState, params.category_id]);

  if (!categoryRank) return null;

  const possibleYears = categoryRank.map((cr) => cr.year);
  const currentCategoryRank = categoryRank.find((cr) => cr.year === selectedYear);
  const ranks = Array.from(currentCategoryRank?.journal_ranks || []);
  ranks.sort((ra, rb) => ra.rank - rb.rank);

  function handleChangeYear(e: ChangeEvent<HTMLSelectElement>) {
    const year = parseInt(e.currentTarget.value, 10);
    setLastJournalRankSelectedYearState(lastJournalRankSelectedYearState.set(params.category_id, year));
    setSelectedYear(year);
  }

  function handleClickDownloadCSV() {
    const fields = [
      { label: 'Rank', value: 'rank' },
      { label: 'Journal', value: 'journal.title' },
      { label: 'Impact Factor(IF)', value: 'impact_factor' },
      { label: 'Category Rank', value: 'if_rank' },
      { label: 'Best IF Percentage', value: 'if_top_percentile' },
      { label: 'Best MRN IF', value: 'top_mrn_if' },
    ];

    try {
      const csv = parse(currentCategoryRank!.journal_ranks, { fields });
      const anchor = document.createElement('a');
      anchor.download = `${currentCategory?.name}.csv`;
      anchor.href = 'data:text/csv;charset=utf-8,' + escape(csv);
      anchor.click();
    } catch (err) {
      alert((err as AxiosError).response?.data?.error?.message || (err as Error).message);
    }
  }

  return (
    <>
      <Helmet>
        <title> {currentCategory?.name} Rank | Xenure</title>
      </Helmet>
      <PageWrapper>
        <h1 style={{ fontFamily: 'Georgia, serif', textAlign: 'center', marginBottom: '50px' }}>
          {currentCategory?.name || 'loading ...'}
        </h1>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <div>
            <div style={{ width: '340px' }}>
              <CategorySelector
                defaultOption={{ label: currentCategory?.name || 'loading ...', value: params.category_id }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              elementType="button"
              style={{ marginRight: '16px', height: '35px' }}
              onClick={handleClickDownloadCSV}
            >
              Download CSV
            </Button>
            <select onChange={handleChangeYear} className="border p-1 rounded w-20">
              <option value={selectedYear} selected disabled hidden>
                {selectedYear ?? 'YEAR'}
              </option>
              {possibleYears.map((y) => (
                <option key={y}>{y}</option>
              ))}
            </select>
          </div>
        </div>
        {currentCategoryRank && <JournalRankTable categoryJournalRanks={ranks} />}
      </PageWrapper>
    </>
  );
};

export default JournalRankShow;
