import { Report } from 'model/report';
import { PageableResponse } from 'types/pageableContent';
import Fetcher from './fetcher';

export interface GetReportsParams {
  size?: number;
  page?: number;
  query?: string | null;
}

export async function getReports(params?: GetReportsParams) {
  const res = await Fetcher.instance.get<PageableResponse<Report>>(`/admin/reports`, {
    params: {
      size: params?.size ?? 1000,
      page: params?.page ? params.page - 1 : 0,
      query: params?.query ?? null,
      // status: 'COMPLETE',
    },
  });
  return res.data.data;
}

export async function getReport(reportId: number) {
  const res = await Fetcher.instance.get<{ data: Report }>(`/admin/reports/${reportId}`);
  return res.data.data;
}
