import React, { FC, useState, useRef } from 'react';
import styled from '@emotion/styled';
import FloatingFeedbackForm from 'components/floatingFeedbackForm';
import palette from 'styles/palette';
import { useClickOutsideListener } from 'hooks/useClickoutsideListener';
import responsive from 'styles/responsive';
import { useTranslation } from 'react-i18next';
import SidebarItem from './sidebarItem';

const FormWrapper = styled.div`
  padding: 16px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
`;

const FormBox = styled.div`
  width: 320px;

  @media (max-width: ${responsive.mobile_width}) {
    width: 100%;
  }
`;

const GreetingBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-align: right;
  color: ${palette.gray800};
`;

const GreetingBox = styled.div`
  text-align: left;
  margin-bottom: 8px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 16px;
  word-break: keep-all;
  white-space: pre-wrap;

  b {
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
  }
`;

const LeftSidebarFeedback: FC = () => {
  const { t } = useTranslation('feedback_dropdown');
  const [isOpen, setIsOpen] = useState(false);
  const dom = useRef<HTMLDivElement>(null);

  useClickOutsideListener({ dom: dom.current, cb: () => (isOpen ? setIsOpen(false) : null) });

  return (
    <div className="relative">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => setIsOpen((prev) => !prev)}>
        <SidebarItem isActive={false}>
          <i className="dripicons-broadcast text-xl leading-none mr-3" />
          <span>{t('left_sidebar_button')}</span>
        </SidebarItem>
      </a>
      <div className="absolute bottom-0 ml-4" ref={dom}>
        {isOpen && (
          <FormBox>
            <GreetingBoxWrapper>
              <GreetingBox>
                <b>{t('description_title')}</b>
                <div dangerouslySetInnerHTML={{ __html: t('description_content') }} />
              </GreetingBox>
            </GreetingBoxWrapper>
            <FormWrapper>
              <FloatingFeedbackForm onSubmit={() => setIsOpen(false)} />
            </FormWrapper>
          </FormBox>
        )}
      </div>
    </div>
  );
};

export default LeftSidebarFeedback;
