import React, { FC } from 'react';
import styled from '@emotion/styled';

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = styled.button`
  text-decoration: none;
  text-align: center;
  border-radius: 15px;
  padding: 8px 16px;
  text-transform: capitalize;
`;

const RoundButton: FC<Props> = (props) => {
  return <Button {...props}>{props.children}</Button>;
};

export default RoundButton;
