import Fetcher from 'api/fetcher';
import { Category } from '../model/category';
import { CategoryRank } from '../model/categoryRank';

export async function getCategories() {
  const res = await Fetcher.instance.get<{ data: Category[] }>('/categories');
  return res.data.data.map((c) => ({ label: c.name, value: c.id }));
}

export async function getCategory(categoryId: string) {
  const res = await Fetcher.instance.get<{ data: { id: string; name: string } }>(`/categories/${categoryId}`);
  return res.data.data;
}

export async function getJournalRankInCategory(categoryId: string) {
  const res = await Fetcher.instance.get<{ data: CategoryRank[] }>(`/categories/${categoryId}/journal-ranks`);
  const ranks = res.data.data;
  ranks.sort((a, b) => a.year - b.year);
  return ranks;
}
