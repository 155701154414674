import React, { FC, useState } from 'react';
import { Button } from '@pluto_network/pluto-design-elements';
import SignUpForm from 'components/signUpForm';
import Footer from 'components/footer';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import responsive from 'styles/responsive';
import { signInDemo } from 'api/auth';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import gtm, { EventName } from '../../helpers/gtm';
import { Helmet } from 'react-helmet';

enum SIGN_UP_STEP {
  FORM,
  SUCCESS,
}

const PageContainer = styled.div`
  width: 700px;
  margin: 0 auto;

  @media (max-width: ${responsive.tablet_width}) {
    width: 100%;
  }
`;

const SucceedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  b {
    color: ${palette.main_blue1};
    font-weight: 500;
  }
`;

const SignUpFormWrapper = styled.div`
  padding: 40px 0 120px 0;
`;

const HomeButton = styled(Button)`
  margin-right: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 32px;
`;

const SignUpPage: FC = () => {
  const { t } = useTranslation('sign_up_page');
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutateAsync: signInDemoMutate } = useMutation('/auth/login', signInDemo, {
    onSuccess: (user) => {
      queryClient.setQueryData('/auth/login', user);
      history.push('/overview');
      gtm.event(EventName.LOGIN_DEMO);
    },
    onError: (err: any) => {
      const errorResponse = err.response?.data?.error?.message;
      alert(errorResponse || err);
    },
  });
  const [step, setStep] = useState<SIGN_UP_STEP>(SIGN_UP_STEP.FORM);

  async function handleClickViewDemo() {
    await signInDemoMutate();
  }

  return (
    <>
      <Helmet>
        <title>Sign up | Xenure</title>
      </Helmet>
      <PageContainer>
        <div>
          {step === SIGN_UP_STEP.SUCCESS && (
            <SucceedContentWrapper>
              <h3 style={{ marginBottom: '56px' }}>{t('finish_to_sign_up')}</h3>
              <div dangerouslySetInnerHTML={{ __html: t('finish_to_sign_up_content') }} />
              <ButtonWrapper>
                <HomeButton
                  color="black"
                  size="large"
                  variant="outlined"
                  elementType="link"
                  to="/"
                  aria-label="Xenure go home button"
                >
                  <span>{t('back_to_main')}</span>
                </HomeButton>
                <Button
                  color="blue"
                  size="large"
                  type="button"
                  elementType="button"
                  aria-label="Xenure view demo button"
                  onClick={handleClickViewDemo}
                >
                  <span>{t('try_service_as_demo')}</span>
                </Button>
              </ButtonWrapper>
            </SucceedContentWrapper>
          )}
          {step === SIGN_UP_STEP.FORM && (
            <SignUpFormWrapper>
              <SignUpForm onSucceedCreate={() => setStep(SIGN_UP_STEP.SUCCESS)} />
            </SignUpFormWrapper>
          )}
        </div>
      </PageContainer>
      <Footer />
    </>
  );
};

export default SignUpPage;
