import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureItem from '../../featureItem';

const FeatureSection: FC = () => {
  const { t } = useTranslation('about_page');

  return (
    <div className="bg-white py-16 container mx-auto">
      <div
        className="text-4xl text-center tracing-tight mt-8 text-gray-800 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: t('feature_section_title') }}
      />
      <ul className="mt-24 grid grid-cols-2 grid-flow-row gap-4">
        <FeatureItem
          title={t('main_feature_title_1')}
          imgSrc="/img/pub_list.png"
          alt="publication list"
          content={<div className="space-y-3" dangerouslySetInnerHTML={{ __html: t('main_feature_desc_1') }} />}
        />
        <FeatureItem
          title={t('main_feature_title_2')}
          imgSrc="/img/example_paper_rank.png"
          alt="paper rank in journal"
          reverse
          content={<div className="space-y-3" dangerouslySetInnerHTML={{ __html: t('main_feature_desc_2') }} />}
        />
        <FeatureItem
          title={t('main_feature_title_3')}
          imgSrc="/img/citation_dist.png"
          alt="paper journal distribution"
          content={<div className="space-y-3" dangerouslySetInnerHTML={{ __html: t('main_feature_desc_3') }} />}
        />
        <FeatureItem
          title={t('main_feature_title_4')}
          imgSrc="/img/citation_trends.png"
          alt="citation trend"
          reverse
          content={<div className="space-y-3" dangerouslySetInnerHTML={{ __html: t('main_feature_desc_4') }} />}
        />
        <FeatureItem
          title={t('main_feature_title_5')}
          imgSrc="/img/research_network.png"
          alt="research network graph"
          content={<div className="space-y-3" dangerouslySetInnerHTML={{ __html: t('main_feature_desc_5') }} />}
        />
        <FeatureItem
          title={t('main_feature_title_6')}
          imgSrc="/img/weekly_recommendation.png"
          alt="weekly recommendation"
          reverse
          content={<div className="space-y-3" dangerouslySetInnerHTML={{ __html: t('main_feature_desc_6') }} />}
        />
      </ul>
    </div>
  );
};

export default FeatureSection;
