import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const DefaultHelmet: FC = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#3e7fff" />
      <title>Xenure</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />
      <meta itemProp="name" content="Xenure" />
      <meta name="description" content="Most efficient way to manage your publication list" />
      <meta name="twitter:description" content="Most efficient way to manage your publication list" />
      <meta name="twitter:card" content="Xenure" />
      <meta name="twitter:title" content="Xenure" />
      <meta property="twitter:image" content="%PUBLIC_URL%/og_image.png" />
      <meta property="og:title" content="Xenure" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={`https://www.xenure.com`} />
      <meta property="og:description" content="Most efficient way to manage your publication list" />
      <meta property="og:image" content="%PUBLIC_URL%/og_image.png" />
    </Helmet>
  );
};

export default DefaultHelmet;
