import { checkLoggedIn, SignInResult } from 'api/auth';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { SAMPLE_USER_TOKEN_KEY } from 'constants/sessionStorage';
import Fetcher from 'api/fetcher';
import { useSetRecoilState } from 'recoil';
import { reportState } from 'atom/reports';

const AUTH_DEDUPING_INTERVAL = 5 * 60 * 1000;

export function useCurrentUser() {
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  const setReports = useSetRecoilState(reportState);

  // NOTE: need this logic for sample user
  if (queryParams.token) {
    Fetcher.instance.defaults.headers.common['X-Sample-Token'] = queryParams.token;
    sessionStorage.setItem(SAMPLE_USER_TOKEN_KEY, queryParams.token as string);
  }

  const { data, isLoading, error } = useQuery('/auth/login', checkLoggedIn, {
    staleTime: AUTH_DEDUPING_INTERVAL,
    onSuccess: (res) => {
      if (!res) return;

      if (res.is_token_expired || (res.user && res.user.status !== 'SAMPLE' && res.user.status !== 'IN_TRANSITION')) {
        delete Fetcher.instance.defaults.headers.common['X-Sample-Token'];
        sessionStorage.removeItem(SAMPLE_USER_TOKEN_KEY);
      }

      if (res.user) {
        setReports({ report: res.user.report });
      }
    },
  });

  return {
    ...(data as SignInResult),
    isLoading,
    error,
  };
}
