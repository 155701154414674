import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import InvitationModalContent from '../invitationModal';
import { useSetRecoilState } from 'recoil';
import { globalDialogState } from '../../atom/globalModal';
import SidebarFeedback from './sidebarFeedback';
import SidebarItem from './sidebarItem';
import UserDropdown from './userDropdown';
import LockIcon from '../icons/lock';
import classNames from 'classnames';
import LockedLink from 'components/LockedLink';

const AuthMenuItem: FC = () => {
  const { is_demo } = useCurrentUser();
  const setGlobalDialog = useSetRecoilState(globalDialogState);

  if (is_demo) {
    return (
      <Link className="gotcha" to="/sign-up">
        <SidebarItem isActive={false}>
          <i className="text-xl leading-none dripicons-user mr-3" />
          <span>Sign up</span>
        </SidebarItem>
      </Link>
    );
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="gotcha"
        onClick={() => {
          setGlobalDialog({ content: <InvitationModalContent /> });
        }}
      >
        <SidebarItem isActive={false}>
          <i className="text-xl leading-none dripicons-rocket mr-3" />
          <span>Send Invitation</span>
        </SidebarItem>
      </a>
    </>
  );
};

const Sidebar: FC = () => {
  const location = useLocation();
  const { user } = useCurrentUser();

  const isLocked = user?.status === 'SAMPLE' || user?.status === 'IN_TRANSITION';

  return (
    <nav className="fixed w-72 h-screen bg-gray-700 text-gray-300 text-sm flex-shrink-0 top-0 flex flex-col justify-between pt-2 pb-8 z-10">
      <ul className="pt-14">
        <Link to="/overview">
          <SidebarItem isActive={location.pathname.includes('/overview')}>
            <i className="text-xl leading-none dripicons-preview mr-3" />
            <span>Overview</span>
          </SidebarItem>
        </Link>
        <Link to="/publications">
          <SidebarItem isActive={location.pathname.includes('/publications')}>
            <i className="text-xl leading-none dripicons-document mr-3" />
            <span>Publications</span>
          </SidebarItem>
        </Link>
        <Link to="/research/network">
          <SidebarItem isActive={location.pathname.includes('/research/network')}>
            <i className="text-xl leading-none dripicons-web mr-3" />
            <span>Research Network</span>
          </SidebarItem>
        </Link>
        <Link to="/analytics/personal">
          <SidebarItem isActive={location.pathname.includes('/analytics/personal')}>
            <i className="text-xl leading-none dripicons-graph-line mr-3" />
            <span>Personal Analytics</span>
          </SidebarItem>
        </Link>
        <Link to="/analytics/citation">
          <SidebarItem isActive={location.pathname.includes('/analytics/citation')} isPreview>
            <i className="text-xl leading-none dripicons-graph-pie mr-3" />
            <span>Citation Analytics</span>
          </SidebarItem>
        </Link>
        <LockedLink to="/recommendation/weekly" isLocked={!!isLocked}>
          <SidebarItem isActive={location.pathname.includes('/recommendation/weekly')} isPreview>
            <i
              className={classNames('text-xl leading-none dripicons-lightbulb mr-3', {
                'opacity-50': isLocked,
              })}
            />
            <span className="flex items-center">
              <span className={classNames({ 'opacity-50': isLocked })}>Weekly Recommendation</span>
              {isLocked && <LockIcon className="inline-block w-5 h-5 text-gray-200 ml-1" />}
            </span>
          </SidebarItem>
        </LockedLink>
        <Link to="/journal-rank">
          <SidebarItem isActive={location.pathname.includes('/journal-rank')} isPreview>
            <i className="text-xl leading-none dripicons-star mr-3" />
            <span>Journal Rank</span>
          </SidebarItem>
        </Link>
        <Link className="gotcha" to="/institution-analytics">
          <SidebarItem isActive={location.pathname.includes('/institution-analytics')} isAdvanced>
            <i className="text-xl leading-none dripicons-monitor mr-3" />
            <span>Institution Research Analytics</span>
          </SidebarItem>
        </Link>
      </ul>
      <div className="py-2">
        <ul className="p-2">
          <AuthMenuItem />
          <SidebarFeedback />
        </ul>
        <UserDropdown />
      </div>
    </nav>
  );
};

export default Sidebar;
