import React, { FC } from 'react';
import LoadingSpinner from '../common/loadingSpinner';

const Fallback: FC = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-100">
      <LoadingSpinner />
    </div>
  );
};

export default Fallback;
