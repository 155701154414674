export default function copySelectedTextToClipboard(text: string) {
  try {
    const textField = document.createElement('textarea');
    textField.textContent = text;
    textField.style.whiteSpace = 'pre';
    document.body.appendChild(textField);

    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNode(textField);

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      document.body.removeChild(textField);
    } else {
      document.body.removeChild(textField);
    }

    alert('Copied!');
  } catch (err) {
    console.error('Failed to copy the given text. Please use other browser(we recommend the latest Chrome browser.)');
  }
}
