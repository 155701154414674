import React from 'react';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import responsive from 'styles/responsive';
import SignInForm from 'components/signInForm';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MainContainer = styled.section`
  display: flex;
  padding-top: 75px;
  height: 100vh;
  background-image: url(https://assets.pluto.im/scinapse-new-background.webp);
`;

const MainContentBox = styled.div`
  @media (max-width: ${responsive.tablet_width}) {
    text-align: center;
  }
`;

const Catchphrase = styled.div`
  color: ${palette.black1};
  text-shadow: 0 1px 3px rgba(255, 255, 255, 0.2);
  font-size: 52px;
  font-weight: 400;
  line-height: 1.05;
  margin-bottom: 16px;
  letter-spacing: -0.015em;

  b {
    color: ${palette.black0};
    font-weight: 900;
  }

  @media (max-width: ${responsive.tablet_width}) {
    font-size: 28px;
    margin-bottom: 16px;
  }
`;

const SubContent = styled.div`
  color: ${palette.gray800};
  font-size: 24px;
  margin-bottom: 32px;

  @media (max-width: ${responsive.tablet_width}) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const MobileSubContent = styled.div`
  font-size: 14px;
  color: ${palette.gray700};
  margin-top: 16px;
  display: none;

  @media (max-width: ${responsive.tablet_width}) {
    display: block;
  }
`;

type Props = {
  onClickViewDemoButton: () => void;
};

const MainSection: React.FC<Props> = ({ onClickViewDemoButton }) => {
  const { t } = useTranslation(['about_page', 'header', 'general']);
  const { user } = useCurrentUser();

  return (
    <MainContainer>
      <div className="flex h-full items-center container justify-center mx-auto space-x-32">
        <MainContentBox>
          <Catchphrase dangerouslySetInnerHTML={{ __html: t('main_catchphrase') }} />
          <SubContent>{t('sub_catchphrase')}</SubContent>
          {!user && (
            <div>
              <div className="space-x-2">
                <Link
                  className="gotcha p-3 bg-blue-500 rounded-md text-white"
                  to="/sign-up"
                  aria-label="Xenure sign up button"
                >
                  <span style={{ textTransform: 'capitalize' }}>{t('general:sign_up')}</span>
                </Link>
                <button
                  className="p-3 bg-ruby rounded-md text-white"
                  aria-label="Xenure view demo button"
                  onClick={onClickViewDemoButton}
                >
                  <span style={{ textTransform: 'capitalize' }}>{t('header:start_demo')}</span>
                </button>
              </div>
              <MobileSubContent>{t('mobile_only_guide')}</MobileSubContent>
            </div>
          )}
        </MainContentBox>
        <div>
          <SignInForm />
        </div>
      </div>
      <div className="absolute bottom-4 w-full flex justify-center animate-bounce">
        <i className="mdi mdi-chevron-double-down" style={{ fontSize: '32px' }} />
      </div>
    </MainContainer>
  );
};

export default MainSection;
