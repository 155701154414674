import React, { useCallback } from 'react';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button, InputField } from '@pluto_network/pluto-design-elements';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import validateEmail from 'helpers/validateEmail';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { signIn, signOut } from 'api/auth';
import LoadingSpinner from 'components/common/loadingSpinner';
import { useHistory } from 'react-router-dom';
import FeedbackForm from 'components/feedbackForm';
import { DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY } from 'components/demoGuideModal';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { globalDialogState } from 'atom/globalModal';
import ResetPasswordModal from 'components/resetPasswordModal';
import { useMutation, useQueryClient } from 'react-query';
import gtm, { EventName } from '../../helpers/gtm';
import { useLogInDemo } from '../../hooks/useLoginDemo';
import { reportState } from 'atom/reports';

const LinkList = styled.div`
  color: ${palette.main_blue1};
  display: flex;
  font-size: 12px;
  justify-content: center;

  a + a {
    margin-left: 2px;

    &:before {
      content: ' | ';
    }
  }
`;

const StartButton = styled(Button)`
  margin-top: 16px;

  &:hover {
    color: white;
  }
`;

const SignUpButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

type SignInFormValues = {
  email: string;
  password: string;
};

const validateForm = (values: SignInFormValues) => {
  const errors: FormikErrors<SignInFormValues> = {};
  if (!validateEmail(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!values.password || values.password.length < MINIMUM_PASSWORD_LENGTH) {
    errors.password = 'Minimum length is 8';
  }

  return errors;
};

const TitleWrapper = styled.div`
  font-size: 18px;
  color: ${palette.main_blue0};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const SubTitleWrapper = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${palette.gray600};
`;

const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;

const SignInForm: React.FC = () => {
  const { t } = useTranslation(['sign_in_form', 'header', 'general']);
  const { user, is_demo } = useCurrentUser();
  const [, setGlobalDialog] = useRecoilState(globalDialogState);
  const history = useHistory();
  const { mutate: loginDemo } = useLogInDemo();
  const setReports = useSetRecoilState(reportState);

  const queryClient = useQueryClient();
  const { mutateAsync: signInMutate } = useMutation('/auth/login', signIn, {
    onSuccess: (user) => {
      queryClient.setQueryData('/auth/login', user);
      setReports({ report: user.user.report });
      history.push('/overview');
      gtm.event(EventName.LOGIN, { method: 'password' });
    },
    onError: () => {
      window.alert(t('failed_to_sign_in_error_msg'));
    },
  });

  const { mutateAsync: signOutMutate } = useMutation('/auth/login', signOut, {
    onSuccess: () => {
      Cookies.remove(DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY);
      queryClient.setQueryData('/auth/login', null);
      gtm.event(is_demo ? EventName.LOGOUT_DEMO : EventName.LOGOUT);
    },
  });

  const handleSubmit = useCallback(
    async (values: SignInFormValues, { setErrors }: FormikHelpers<SignInFormValues>) => {
      const errors = validateForm(values);
      if (errors.email || errors.password) {
        return setErrors(errors);
      }

      await signInMutate(values);
    },
    [signInMutate]
  );

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: handleSubmit,
  });

  if (user && !user.is_confirmed) {
    return (
      <div className="text-sm p-4 bg-white border">
        <h1 style={{ marginBottom: '16px', fontSize: '24px', textAlign: 'center' }}>{t('accept_in_progress')}</h1>
        <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: t('accept_in_progress_desc') }} />
        <FeedbackForm />
        <hr />
        <LinkList className="mt-4">
          <a href="https://www.pluto.im" target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
            About us
          </a>
          <a href="https://www.xenure.com" target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
            About Xenure
          </a>
          <a
            href="https://www.scinapse.io/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'block' }}
          >
            Terms of service
          </a>
          <a
            href="https://www.scinapse.io/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'block' }}
          >
            Privacy policy
          </a>
        </LinkList>
      </div>
    );
  }

  if (user)
    return (
      <div className="w-full bg-white p-8 border rounded">
        <h4 className="text-xl text-center font-bold">
          {t('welcome_message_for_signed_user', { first_name: user.first_name, last_name: user.last_name })}
          👋
        </h4>
        {is_demo && <p className="text-muted text-center">({t('currently_using_demo_version')})</p>}
        <StartButton
          size="large"
          type="button"
          elementType="link"
          to="/overview"
          aria-label="Xenure start button"
          fullWidth
        >
          <span>{t('general:start')}</span>
        </StartButton>
        <Button
          className="mt-2"
          size="large"
          type="button"
          color="gray"
          elementType="button"
          aria-label="Xenure exit button"
          fullWidth
          onClick={async () => {
            await signOutMutate();
          }}
        >
          <span>{is_demo ? t('general:finish') : t('general:logout')}</span>
        </Button>
      </div>
    );

  if (formik.isSubmitting) {
    return (
      <div className="w-full">
        <div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '24px' }}>
              <LoadingSpinner />
            </div>
            <h4 className="mt-0 header-title text-center">{t('loading_message_title')}</h4>
            <p className="text-muted mb-4 text-center">{t('loading_message_desc')}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 w-96 border rounded">
      <TitleWrapper className="text-center">{t('service_title')}</TitleWrapper>
      <SubTitleWrapper className="my-4">{t('welcome_message_for_unsigned_user')}</SubTitleWrapper>
      <form onSubmit={formik.handleSubmit}>
        <FieldWrapper>
          <InputField
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
            placeholder={t('general:email')}
            leadingIcon={<i className="dripicons-mail" />}
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
            placeholder={t('general:password')}
            leadingIcon={<i className="dripicons-lock" />}
          />
        </FieldWrapper>
        <div className="space-y-2">
          <button
            type="submit"
            aria-label="Xenure sign in button"
            className="bg-blue-500 bg-white py-2 px-3 rounded-md hover:bg-blue-400 text-white w-full"
          >
            <span>{t('general:sign_in')}</span>
          </button>
          <button
            type="button"
            aria-label="try demo button"
            className="bg-ruby bg-white py-2 px-3 rounded-md text-white w-full"
            onClick={() => loginDemo()}
          >
            <span>{t('header:start_demo')}</span>
          </button>
        </div>
        <SignUpButtonWrapper>
          <Button
            type="button"
            elementType="button"
            size="small"
            variant="text"
            color="gray"
            onClick={() => setGlobalDialog({ content: <ResetPasswordModal /> })}
          >
            <span>{t('reset_password_button')}</span>
          </Button>
          <Button className="gotcha" elementType="link" size="small" variant="text" color="gray" to="/sign-up">
            <i className="dripicons-user" />
            <span>{t('general:sign_up')}</span>
          </Button>
        </SignUpButtonWrapper>
      </form>
    </div>
  );
};

export default SignInForm;
