import React, { useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { Button } from '@pluto_network/pluto-design-elements';
import validateEmail from 'helpers/validateEmail';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import FormikInput from 'components/common/formikInput';
import { createUser } from 'api/auth';
import Cookies from 'js-cookie';
import { INVITED_EMAIL_COOKIE_KEY } from 'pages/about';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import DebouncedAffiliationInput, { SelectableAffiliation } from 'components/affiliationInput';
import gtm, { EventContext, EventName, EventPosition } from '../../helpers/gtm';
import Logo from 'components/icons/logo';
import { useSetRecoilState } from 'recoil';
import { reportState } from 'atom/reports';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  affiliation: {
    affiliationId: string;
    affiliationName: string;
  };
  signUpPathReason: string;
  referrer: string;
  publicationList: string;
}

enum SignUpPathType {
  REFERRER = 'REFERRER',
  COMMUNITY = 'COMMUNITY',
  ORGANIC_SEARCH = 'ORGANIC_SEARCH',
  OTHER = 'OTHER',
}

const labelClassName = 'block text-sm font-bold text-gray-500 pl-2 mb-2';

interface Props {
  onSucceedCreate: () => void;
  initialValues?: Partial<FormValues>;
}

const SignUpForm: React.FC<Props> = ({ onSucceedCreate, initialValues }) => {
  const { t } = useTranslation(['sign_up_page', 'general']);
  const queryClient = useQueryClient();
  const setReports = useSetRecoilState(reportState);
  const { mutateAsync: signUpMutate } = useMutation('/auth/login', createUser, {
    onSuccess: (user) => {
      queryClient.setQueryData('/auth/login', user);
      setReports({ report: user.report });
      if (user.is_confirmed) {
        history.push('/overview');
      } else {
        onSucceedCreate();
      }
      gtm.event(EventName.SIGN_UP, { context: EventContext.SIGN_UP, position: EventPosition.BODY, method: 'organic' });
    },
    onError: (err: any) => {
      const errorResponse = err?.response?.data.error;
      gtm.event(EventName.SIGN_UP_FAIL, {
        context: EventContext.SIGN_UP,
        position: EventPosition.BODY,
        reason: errorResponse?.message || err,
      });
      alert(errorResponse?.message || err);
    },
  });

  const invitedCode = Cookies.get(INVITED_EMAIL_COOKIE_KEY);
  const [signUpPathType, setSignUpPathType] = useState<SignUpPathType | ''>('');
  const history = useHistory();

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!validateEmail(values.email)) {
      errors.email = t('email_validate_error');
    }

    if (!values.password || values.password.length < MINIMUM_PASSWORD_LENGTH) {
      errors.password = t('password_validate_error', { count: MINIMUM_PASSWORD_LENGTH });
    }

    if (!values.confirmPassword || values.confirmPassword.length < MINIMUM_PASSWORD_LENGTH) {
      errors.confirmPassword = t('password_validate_error', { count: MINIMUM_PASSWORD_LENGTH });
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t('confirm_password_validate_error');
    }

    if (!values.firstName) {
      errors.firstName = t('first_name_validate_error');
    }

    if (!values.lastName) {
      errors.lastName = t('last_name_validate_error');
    }

    if (!values.affiliation.affiliationName) {
      errors.affiliation = {
        affiliationName: t('affiliation_validate_error'),
      };
    }

    return errors;
  };

  async function handleSubmit({
    email,
    password,
    firstName,
    lastName,
    affiliation,
    publicationList,
    referrer,
    signUpPathReason,
  }: FormValues) {
    let referrerValue = `${invitedCode} | ${referrer}`;
    if (signUpPathType === SignUpPathType.COMMUNITY || signUpPathType === SignUpPathType.OTHER) {
      referrerValue = `${invitedCode} | ${referrer} | ${SignUpPathType[signUpPathType]} - ${signUpPathReason}`;
    } else if (signUpPathType === SignUpPathType.ORGANIC_SEARCH || signUpPathType === SignUpPathType.REFERRER) {
      referrerValue = `${invitedCode} | ${referrer} | ${SignUpPathType[signUpPathType]}`;
    }
    await signUpMutate({
      email,
      password,
      firstName,
      lastName,
      publicationList,
      affiliationId: affiliation.affiliationId,
      affiliationName: affiliation.affiliationName,
      referrer: referrerValue,
    });
  }

  return (
    <div>
      <div className="mb-6">
        <div style={{ textAlign: 'center' }}>
          <Logo />
        </div>
        <div className="mt-8 text-center text-xl">{t('general:service_title')}</div>
        <div
          className="text-sm text-gray-500 text-center my-4"
          dangerouslySetInnerHTML={{ __html: t('close_beta_desc') }}
        />
      </div>
      <Formik<FormValues>
        initialValues={{
          email: initialValues?.email ?? '',
          password: '',
          confirmPassword: '',
          firstName: '',
          lastName: '',
          affiliation: { affiliationId: '', affiliationName: '' },
          referrer: '',
          publicationList: '',
          signUpPathReason: '',
        }}
        onSubmit={handleSubmit}
        validate={validateForm}
        validateOnChange={false}
      >
        <Form className="space-y-8">
          <div>
            <div className={labelClassName}>{t('general:email')}</div>
            <Field
              name="email"
              type="email"
              component={FormikInput}
              placeholder={t('email_placeholder')}
              leadingIcon={<i className="dripicons-mail" />}
            />
          </div>
          <div>
            <div className={labelClassName}>{t('general:password')}</div>
            <Field
              name="password"
              type="password"
              component={FormikInput}
              placeholder={t('general:password')}
              leadingIcon={<i className="dripicons-lock" />}
            />
          </div>
          <div>
            <div className={labelClassName}>{t('confirm_password')}</div>
            <Field
              name="confirmPassword"
              type="password"
              component={FormikInput}
              placeholder={t('confirm_password')}
              leadingIcon={<i className="dripicons-lock" />}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <div className={labelClassName}>{t('general:first_name')}</div>
              <Field
                name="firstName"
                type="text"
                component={FormikInput}
                placeholder={t('general:first_name')}
                leadingIcon={<i className="dripicons-user" />}
              />
            </div>
            <div style={{ width: '100%', marginLeft: '16px' }}>
              <div className={labelClassName}>{t('general:last_name')}</div>
              <Field
                name="lastName"
                type="text"
                component={FormikInput}
                placeholder={t('general:last_name')}
                leadingIcon={<i className="dripicons-user" />}
              />
            </div>
          </div>
          <div>
            <div className={labelClassName}>{t('current_affiliation')}</div>
            <Field name="affiliation">
              {({ form }: FieldProps<{ affiliationId: string; affiliationName: string }, FormValues>) => {
                return (
                  <>
                    <DebouncedAffiliationInput
                      hasError={
                        !!form.errors.affiliation?.affiliationName && !!form.touched.affiliation?.affiliationName
                      }
                      errorMsg={form.errors.affiliation?.affiliationName}
                      onBlur={() => form.setFieldTouched('affiliation.affiliationId')}
                      onChange={(value) => form.setFieldValue('affiliation', { affiliationName: value })}
                      onSelect={(affiliation: SelectableAffiliation) => {
                        form.setFieldValue('affiliation', {
                          affiliationId: affiliation.affiliation_id,
                          affiliationName: affiliation.keyword,
                        });
                      }}
                    />
                  </>
                );
              }}
            </Field>
          </div>
          <div className="my-12 text-gray-500 font-bold text-center text-xl">{t('sign_up_path_headline')}</div>
          <div>
            <div className={labelClassName}>{t('sign_up_path_question')}</div>
            <select
              className="p-2 border rounded overflow-hidden w-full"
              onChange={(e) => {
                const { value } = e.currentTarget;
                if (value !== '') {
                  setSignUpPathType(value as SignUpPathType);
                } else {
                  setSignUpPathType('');
                }
              }}
            >
              <option unselectable="on" value="">
                {t('sign_up_path_placeholder')}
              </option>
              <option selected={signUpPathType === SignUpPathType.REFERRER} value={SignUpPathType.REFERRER}>
                {t('sign_up_path_option_referrer')}
              </option>
              <option selected={signUpPathType === SignUpPathType.COMMUNITY} value={SignUpPathType.COMMUNITY}>
                {t('sign_up_path_option_community')}
              </option>
              <option selected={signUpPathType === SignUpPathType.ORGANIC_SEARCH} value={SignUpPathType.ORGANIC_SEARCH}>
                {t('sign_up_path_option_search')}
              </option>
              <option selected={signUpPathType === SignUpPathType.OTHER} value={SignUpPathType.OTHER}>
                {t('sign_up_path_option_other')}
              </option>
            </select>
            {(signUpPathType === SignUpPathType.COMMUNITY || signUpPathType === SignUpPathType.OTHER) && (
              <Field
                name="signUpPathReason"
                type="text"
                component={FormikInput}
                placeholder={
                  signUpPathType === SignUpPathType.COMMUNITY
                    ? t('sign_up_path_community_placeholder')
                    : t('sign_up_path_other_placeholder')
                }
                leadingIcon={<i className="dripicons-user-group" />}
                style={{ marginTop: '8px' }}
              />
            )}
          </div>
          <div>
            <div className={labelClassName}>{t('referrer')}</div>
            <Field
              name="referrer"
              type="text"
              component={FormikInput}
              placeholder={t('referrer_placeholder')}
              leadingIcon={<i className="dripicons-user-group" />}
            />
          </div>
          <div className="my-12 text-gray-500 font-bold text-center text-xl">{t('publication_list_headline')}</div>
          <div>
            <div className={labelClassName}>{t('pulication_list_or_url')}</div>
            <Field
              name="publicationList"
              type="textarea"
              component={FormikInput}
              placeholder={t('pulication_list_or_url_placeholder')}
              style={{ height: '320px' }}
              multiline
            />
          </div>
          <Button size="large" type="submit" elementType="button" fullWidth>
            <span>{t('general:sign_up')}</span>
          </Button>
          <Button
            style={{ marginTop: '8px', backgroundColor: 'white' }}
            to="/"
            size="large"
            type="button"
            elementType="link"
            variant="outlined"
            color="gray"
            fullWidth
          >
            <span>{t('go_back_btn')}</span>
          </Button>
        </Form>
      </Formik>
    </div>
  );
};
export default SignUpForm;
