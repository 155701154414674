import React, { FC } from 'react';
import { Button, InputField } from '@pluto_network/pluto-design-elements';
import copySelectedTextToClipboard from 'helpers/copySelectedTextToClipboard';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import gtm, { EventContext, EventName, EventPosition } from '../../helpers/gtm';
import ModalContent from '../modalContent';
import Logo from 'components/icons/logo';

const GuideText = styled.div`
  font-size: 16px;
  color: ${palette.gray800};
  line-height: 1.75;
`;

const GuideSubText = styled.div`
  margin: 16px 0 32px 0;
  font-size: 14px;
  color: ${palette.gray600};

  b {
    color: ${palette.main_blue1};
    font-weight: 500;
  }
`;

const InvitationModalContent: FC = () => {
  const { t } = useTranslation('top_banner');
  const { user: currentUser } = useCurrentUser();

  if (!currentUser) return null;

  return (
    <ModalContent
      headline={<Logo style={{ width: '160px', height: '60px', marginLeft: '32px' }} />}
      footer={
        <Button
          className="gotcha"
          color="blue"
          elementType="button"
          onClick={() => {
            const url = `https://www.xenure.com/?i=${currentUser.id}`;
            window.open(url, '_blank');
          }}
        >
          <span>Check the URL</span>
        </Button>
      }
    >
      <div className="p-8">
        <GuideText dangerouslySetInnerHTML={{ __html: t('invite_modal_content') }} />
        <GuideSubText dangerouslySetInnerHTML={{ __html: t('invite_modal_guide') }} />
        <InputField
          variant="underlined"
          value={`https://www.xenure.com/?i=${currentUser.id}`}
          leadingIcon={<i className="mdi mdi-link" />}
          trailingIcon={
            <i
              className="mdi mdi-share"
              onClick={() => {
                const url = `https://www.xenure.com/?i=${currentUser.id}`;
                copySelectedTextToClipboard(url);
                gtm.event(EventName.COPY_URL, {
                  context: EventContext.SEND_INVITATION,
                  position: EventPosition.MODAL,
                  url: url,
                });
              }}
              style={{ cursor: 'pointer', fontStyle: 'normal' }}
            >
              <span>Copy URL</span>
            </i>
          }
        />
      </div>
    </ModalContent>
  );
};

export default InvitationModalContent;
