import React, { FC } from 'react';

const MajorCustomerItem: FC<{ imgSrc: string; imgAlt: string; title: string }> = ({ imgSrc, imgAlt, title }) => {
  return (
    <div className="w-1/3 flex flex-col items-center justify-center">
      <img src={imgSrc} alt={imgAlt} />
      <div className="mt-3 text-center">{title}</div>
    </div>
  );
};

export default MajorCustomerItem;
