import React, { FC, memo, useEffect, useState } from 'react';
import { parse } from 'qs';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useQuery } from 'react-query';
import { getReport, getReports } from '../../../api/admin';
import { useRecoilState } from 'recoil';
import { reportState } from '../../../atom/reports';
import { useLocation } from 'react-router-dom';
import { useCombobox } from 'downshift';
import { Report } from 'model/report';
import classNames from 'classnames';

const ReportSelector: FC = memo(() => {
  const location = useLocation();
  const { pri: preSelectedReportId } = parse(location.search, { ignoreQueryPrefix: true });
  const { is_admin } = useCurrentUser();
  const [currentReport, setCurrentReport] = useRecoilState(reportState);
  const [reportList, setReportList] = useState<Report[]>([]);

  const { data: reports } = useQuery(
    ['/admin/reports'],
    async () => {
      const res = await getReports();
      return res.content;
    },
    {
      enabled: is_admin,
      onSuccess: (reports) => setReportList(reports),
    }
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<Report>({
    items: reportList,
    defaultSelectedItem: currentReport.report,
    itemToString: (report) => (report ? `#${report.id} ${report.name}` : ''),
    onSelectedItemChange: ({ selectedItem: report }) => {
      if (report) {
        setCurrentReport({ report });
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setReportList(() => {
        if (!reports) return [];
        if (!inputValue) return reports;

        const numberParsedValue = parseInt(inputValue, 10);
        if (isNaN(numberParsedValue)) {
          return reports.filter((report) => report.name.toLowerCase().includes(inputValue.toLowerCase()));
        }

        return reports.filter((report) => String(report.id).startsWith(String(numberParsedValue)));
      });
    },
  });

  useEffect(() => {
    if (preSelectedReportId) {
      getReport(Number(preSelectedReportId)).then((report) => {
        if (report) {
          setCurrentReport({ report });
        }
      });
    }
  }, [preSelectedReportId, setCurrentReport]);

  // useEffect(() => {
  //   if (reports && preSelectedReportId) {
  //     const report = reports.find((r) => String(r.id) === preSelectedReportId);
  //     if (report) {
  //       selectItem(report);
  //     }
  //   }
  // }, [preSelectedReportId, reports, selectItem, setCurrentReport]);

  if (!is_admin) return null;

  return (
    <div className="relative">
      <div {...getComboboxProps()} className="border p-2 rounded flex">
        <input {...getInputProps()} placeholder="Report 검색 (ID or 이름)" />
        <button type="button" {...getToggleButtonProps()} aria-label="toggle menu">
          <i className="mdi mdi-arrow-down-bold text-gray-700" />
        </button>
      </div>
      <ul {...getMenuProps()} className="absolute max-h-64 overflow-y-auto left-0 inset-x-0 block shadow-md bg-white">
        {isOpen &&
          reportList.map((report, index) => (
            <li
              key={report.id}
              className={classNames('p-2 hover:bg-gray-100 cursor-pointer', {
                'bg-gray-50': highlightedIndex === index,
              })}
              {...getItemProps({ item: report, index })}
            >
              #{report.id} {report.name}
            </li>
          ))}
      </ul>
    </div>
  );
});

export default ReportSelector;
