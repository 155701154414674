import React from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import ReportSelector from '../reportSelector';

const AdminSection = () => {
  const { is_admin } = useCurrentUser();

  if (!is_admin) return null;

  return (
    <div className="flex space-x-2">
      <ReportSelector />
    </div>
  );
};

export default AdminSection;
