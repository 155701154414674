import React, { FC } from 'react';
import { Button } from '@pluto_network/pluto-design-elements';
import { Field, Form, Formik } from 'formik';
import FormikInput from '../../components/common/formikInput';
import Index from '../../components/hoverPictureItem';
import { useTranslation } from 'react-i18next';
import MajorCustomerItem from 'components/majorCustomerItem';
import gtm, { EventContext, EventName, EventPosition } from '../../helpers/gtm';
import { Helmet } from 'react-helmet';
import { sendFeedback } from 'api/feedback';

interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  institution: string;
  comment: string;
}

const FeatureItem: FC<{ title: string; desc: string; icon: JSX.Element }> = ({ title, desc, icon }) => {
  return (
    <div className="p-4 border rounded-md w-1/2 bg-white">
      <div className="flex">
        {icon}
        <div className="text-xl ml-2">{title}</div>
      </div>
      <div className="text-gray-700 mt-4">{desc}</div>
    </div>
  );
};

const InstitutionAnalytics: FC = () => {
  const { t } = useTranslation(['feedback_dropdown']);

  return (
    <>
      <Helmet>
        <title>Institution Analytics | Xenure</title>
      </Helmet>
      <div className="m-auto p-2 sm:p-8 max-w-screen-lg">
        <h1 className="text-6xl font-extrabold leading-snug m-0 pt-8 text-center">
          Real Data.
          <br />
          Real Analytics.
        </h1>
        <h2 className="text-2xl text-gray-600 font-light mt-8 leading-normal text-center">
          Consultancy Service for Research Performance of
          <br />
          individuals, labs, departments, and/or institutions.
        </h2>
        <div className="mt-12 flex justify-center">
          <Button
            className="gotcha"
            elementType="anchor"
            href="https://www.pluto.im/solution"
            target="_blank"
            size="large"
            color="gray"
          >
            Learn more
          </Button>
          <Button elementType="anchor" href="#contact-section" size="large" className="ml-4">
            Contact us
          </Button>
        </div>
      </div>
      <div className="bg-gray-100 py-16">
        <div className="m-auto p-2 sm:p-8 max-w-screen-lg">
          <div className="text-3xl font-medium text-center">
            Research performance analytics from the right point of view
          </div>
          <div className="font-light text-center text-gray-600 mt-6">
            Get the robust analytics and insights of your institution.
            <br />
            From the world best accurate, filtered, unique database with AI.
          </div>
          <div className="space-y-4 mt-16">
            <div className="flex space-x-4">
              <Index src="/img/example_heatmap.png" alt="example heatmap graph" />
              <Index src="/img/example_histogram.png" alt="example histogram" />
            </div>
            <div className="flex space-x-4">
              <Index src="/img/example_radial_graph.png" alt="example radial graph" />
              <Index src="/img/example_table.png" alt="table example" />
            </div>
          </div>
          <h2 className="text-4xl font-bold mt-24 text-center">Main solutions</h2>
          <div className="mt-16 space-y-4">
            <div className="flex space-x-4">
              <FeatureItem
                title="연구 성과 분석 리포트"
                desc="Pluto만의 차별화된 Data processing 기술을 통해 최고의 연구 성과 및 영향력 분석 리포트를 제공합니다."
                icon={
                  <svg height="32" viewBox="0 0 415.882 415.882" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M401.029 415.882H14.853C6.643 415.882 0 409.238 0 401.029V14.853C0 6.643 6.643 0 14.853 0h386.176c8.21 0 14.853 6.643 14.853 14.853v386.176c0 8.209-6.644 14.853-14.853 14.853zM29.706 386.176h356.47V29.706H29.706z" />
                    <path d="M341.617 326.764h-59.412c-8.21 0-14.853-6.643-14.853-14.853V103.97c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v207.941c0 8.21-6.643 14.853-14.853 14.853zm-44.559-29.706h29.706V118.823h-29.706zM237.647 326.764h-59.412c-8.21 0-14.853-6.643-14.853-14.853V163.382c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v148.529c0 8.21-6.644 14.853-14.853 14.853zm-44.559-29.706h29.706V178.235h-29.706zM133.676 326.764H74.265c-8.21 0-14.853-6.643-14.853-14.853v-89.117c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v89.117c-.001 8.21-6.644 14.853-14.854 14.853zm-44.559-29.706h29.706v-59.412H89.117z" />
                  </svg>
                }
              />
              <FeatureItem
                title="최근 급성장한 세부 연구 토픽"
                desc="기관에서 채용 및 후속 연구 주제를 선정할 떄 참고할 수 있도록, 분야 내에서 최근 급상승 중인 세부 연구 주제에
            대한 정보를 제공합니다."
                icon={
                  <svg height="32" viewBox="0 0 475.3 475.3" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M396.782 475.297c-2.132 0-4.279-.451-6.281-1.395L237.65 402.418 84.798 473.901c-5.439 2.515-11.879 1.541-16.274-2.485a14.877 14.877 0 01-3.989-16.003l58.251-163.475L4.355 173.26a14.89 14.89 0 01-3.22-16.221c2.292-5.567 7.717-8.507 13.722-8.507h159.277L223.667 8.899c4.206-11.861 23.759-11.861 27.965 0l49.534 139.634h159.277c6.005 0 11.43 2.94 13.722 8.507a14.89 14.89 0 01-3.22 16.221L352.513 291.938l58.251 163.475a14.875 14.875 0 01-3.989 16.003 14.814 14.814 0 01-9.993 3.881zM237.65 371.11c2.147 0 4.293.465 6.281 1.395l126.902 59.346-49.447-138.78a14.896 14.896 0 013.481-15.523l99.72-99.928H290.694a14.835 14.835 0 01-13.983-9.884L237.65 58.158l-39.061 109.579a14.834 14.834 0 01-13.983 9.884H50.713l99.72 99.928a14.896 14.896 0 013.481 15.523l-49.447 138.78 126.902-59.346a14.809 14.809 0 016.281-1.396z" />
                  </svg>
                }
              />
            </div>
            <div className="flex space-x-4">
              <FeatureItem
                title="타 기관과의 연구 성과 벤치마크"
                desc="최신 데이터를 기준으로 faculty member 혹은 특정 그룹 벤치마크를 제공합니다. 해당 연구분야에 주요한 대학교,
            연구소, 대륙 간 벤치마크도 보실 수 있습니다."
                icon={
                  <svg height="32" viewBox="0 0 475.293 475.293" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M237.647 0C106.61 0 0 106.61 0 237.647s106.61 237.647 237.647 237.647 237.647-106.61 237.647-237.647S368.683 0 237.647 0zm207.188 222.794H252.5V30.458c102.799 7.32 185.015 89.537 192.335 192.336zM101.679 80.677c32.979-28.603 74.957-46.932 121.113-50.219v171.333L101.679 80.677zm-71.974 156.97c0-51.994 19.315-99.468 50.972-135.966l135.966 135.966L80.676 373.613c-31.656-36.498-50.971-83.972-50.971-135.966zm207.94 207.94c-51.994 0-99.468-19.315-135.966-50.972L243.795 252.5h201.038c-7.669 107.725-97.54 193.087-207.188 193.087z" />
                  </svg>
                }
              />
              <FeatureItem
                title="대학 순위 추이 분석 및 예측"
                desc="대학 순위 변화에 원인 분석 및 성장 가능성 있는 분야를 추천합니다."
                icon={
                  <svg height="32" viewBox="0 0 415.882 415.882" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M401.029 0H14.853C6.643 0 0 6.643 0 14.853v386.176c0 8.21 6.643 14.853 14.853 14.853h386.176c8.21 0 14.853-6.643 14.853-14.853V14.853c0-8.21-6.644-14.853-14.853-14.853zm-14.853 29.706V97.82L252.5 231.497l-63.763-63.763c-5.396-5.425-14.041-5.845-19.901-1.001L29.706 280.566V29.706zm-356.47 356.47v-67.224l147.528-120.714 64.764 64.764c5.802 5.802 15.201 5.802 21.003 0l123.175-123.175v246.35l-356.47-.001z" />
                  </svg>
                }
              />
            </div>
            <div className="flex space-x-4">
              <FeatureItem
                title="연구 분야 내/외 영향 및 범위 분석"
                desc="특정 연구 분야의 영향력과 최신 트렌드를 정리하여 제공합니다."
                icon={
                  <svg height="32" viewBox="0 0 475.293 475.293" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M59.412 356.47h59.412v-29.706H59.412v-89.117h59.412v-29.706H59.412v-89.117h59.412V89.117H59.412V0H29.706v415.882H0v29.706h29.706v29.706h29.706v-29.706h415.882v-29.706H59.412z" />
                    <path d="M460.439 118.838h-103.97v29.706h68.115L311.968 261.159l-63.386-64.024a14.869 14.869 0 00-10.516-4.409c-4.642.131-7.76 1.552-10.545 4.351L123.188 301.41l21.003 21.003 93.774-93.774 63.386 64.024a14.869 14.869 0 0010.516 4.409c4.714-1.262 7.76-1.552 10.545-4.351l123.175-123.175v68.115h29.706v-103.97c-.001-8.21-6.644-14.853-14.854-14.853z" />
                  </svg>
                }
              />
              <FeatureItem
                title="앞으로 영향력이 커질 연구 분야 예측"
                desc="AI, 데이터사이언티스트, 전문 연구진이 협업하여 만든 플루토만의 정교한 예측 시스템으로 앞으로 영향력이 커질
            것이라 예측되는 연구 분야들을 추천드립니다."
                icon={
                  <svg height="32" viewBox="0 0 475.293 475.293" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M193.392 154.375l48.533 49.142c2.785 2.828 5.294 4.366 10.574 4.424 3.945 0 7.717-1.567 10.501-4.351l63.763-63.763v38.409h29.706v-74.265c0-8.21-6.643-14.853-14.853-14.853h-74.265v29.706h38.409l-53.189 53.189-48.533-49.142a14.837 14.837 0 00-10.53-4.424c-3.641.116-7.746 1.552-10.545 4.351l-89.495 89.495 21.003 21.003z" />
                    <path d="M430.734 0H44.559C19.988 0 0 19.988 0 44.559v297.058c0 24.571 19.988 44.559 44.559 44.559h117.967a146.5 146.5 0 01-1.957 13.669c-4.566 23.75-13.843 38.894-19.003 45.743h-22.744v29.706H356.47v-29.706h-22.742c-6.088-8.083-17.913-27.715-20.961-59.412h117.967c24.571 0 44.559-19.988 44.559-44.559V44.559C475.293 19.987 455.306 0 430.734 0zM44.559 29.706h386.176c8.195 0 14.853 6.658 14.853 14.853v222.794H29.706V44.559c0-8.195 6.658-14.853 14.853-14.853zm254.528 415.881H176.206c7.115-13.972 13.881-33.958 15.966-59.412h90.949c2.085 25.455 8.852 45.441 15.966 59.412zm131.647-89.117H44.559c-8.195 0-14.853-6.658-14.853-14.853v-44.559h415.882v44.559c-.001 8.195-6.658 14.853-14.854 14.853z" />
                    <path d="M248.149 316.262c5.8 5.8 5.8 15.205 0 21.005s-15.205 5.8-21.005 0-5.8-15.205 0-21.005 15.205-5.8 21.005 0" />
                  </svg>
                }
              />
            </div>
            <div className="flex space-x-4">
              <FeatureItem
                title="연구 인력 추천 및 유치"
                desc="연구 분야 혹은 차기 연구 분야에 적합한 인재를 추천해드립니다."
                icon={
                  <svg height="32" viewBox="0 0 475.293 475.293" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M401.029 0H103.97C79.399 0 59.412 19.988 59.412 44.559v44.559H29.706v29.706h29.706v103.97H29.706V252.5h29.706v103.97H29.706v29.706h29.706v44.559c0 24.571 19.988 44.559 44.559 44.559h297.058c24.571 0 44.559-19.988 44.559-44.559V44.559C445.587 19.988 425.6 0 401.029 0zm14.853 430.734c0 8.195-6.658 14.853-14.853 14.853H103.97c-8.195 0-14.853-6.658-14.853-14.853V44.559c0-8.195 6.658-14.853 14.853-14.853h297.058c8.195 0 14.853 6.658 14.853 14.853v386.175z" />
                    <path d="M329.651 268.397c-47.257-20.8-107.046-20.8-154.302 0-16.289 7.18-26.819 23.295-26.819 41.049v47.025h207.94v-47.025c0-17.754-10.531-33.869-26.819-41.049zm-2.887 58.367H178.235v-17.319c0-5.99 3.568-11.43 9.094-13.867 39.743-17.493 90.597-17.493 130.34 0 5.526 2.437 9.094 7.876 9.094 13.867l.001 17.319zM252.5 237.647c32.766 0 59.412-26.645 59.412-59.412 0-32.766-26.645-59.412-59.412-59.412-32.766 0-59.412 26.645-59.412 59.412 0 32.766 26.645 59.412 59.412 59.412zm0-89.118c16.376 0 29.706 13.33 29.706 29.706s-13.33 29.706-29.706 29.706-29.706-13.33-29.706-29.706 13.33-29.706 29.706-29.706z" />
                  </svg>
                }
              />
              <FeatureItem
                title="학술지 영향력 추이 분석 및 추천"
                desc="Pluto 만의 차별화된 Data processing 기술을 통해 최고의 연구 성과 및 영향력 분석 리포트를 제공합니다."
                icon={
                  <svg height="32" viewBox="0 0 475.293 475.293" width="32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M401.029 475.293H74.265c-8.21 0-14.853-6.643-14.853-14.853V14.853C59.412 6.643 66.055 0 74.265 0h326.764c8.21 0 14.853 6.643 14.853 14.853V460.44c0 8.21-6.644 14.853-14.853 14.853zM89.117 445.587h297.058V29.706H89.117z" />
                    <path d="M341.617 178.235H133.676c-8.21 0-14.853-6.643-14.853-14.853V74.265c0-8.21 6.643-14.853 14.853-14.853h207.941c8.21 0 14.853 6.643 14.853 14.853v89.117c0 8.21-6.643 14.853-14.853 14.853zm-193.088-29.706h178.235V89.117H148.529zM197.088 304.058h-59.412c-8.21 0-14.853-6.643-14.853-14.853v-59.412c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v59.412c0 8.21-6.643 14.853-14.853 14.853zm-44.559-29.706h29.706v-29.706h-29.706zM350.617 304.058h-59.412c-8.21 0-14.853-6.643-14.853-14.853v-59.412c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v59.412c0 8.21-6.643 14.853-14.853 14.853zm-44.559-29.706h29.706v-29.706h-29.706zM197.088 426.882h-59.412c-8.21 0-14.853-6.643-14.853-14.853v-59.412c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v59.412c0 8.209-6.643 14.853-14.853 14.853zm-44.559-29.706h29.706V367.47h-29.706zM350.617 426.882h-59.412c-8.21 0-14.853-6.643-14.853-14.853v-59.412c0-8.21 6.643-14.853 14.853-14.853h59.412c8.21 0 14.853 6.643 14.853 14.853v59.412c0 8.209-6.643 14.853-14.853 14.853zm-44.559-29.706h29.706V367.47h-29.706z" />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <section className="m-auto p-2 sm:p-16 max-w-screen-lg">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-center relative inline-block">Major customers</h2>
        </div>
        <div className="space-y-4 mt-16">
          <div className="flex space-x-4">
            <MajorCustomerItem
              imgSrc="/img/majorCustomers/seoul_univ.jpg"
              imgAlt="Seoul Univ. School of Chemical and Biological Engineering"
              title="Seoul Univ. School of Chemical and Biological Engineering"
            />
            <MajorCustomerItem
              imgSrc="/img/majorCustomers/postech_dane.jpg"
              imgAlt="POSTECH DANE"
              title="POSTECH DANE"
            />
            <MajorCustomerItem
              imgSrc="/img/majorCustomers/korea_biochar.jpg"
              imgAlt="Korea Univ. Biochar Research Center"
              title="Korea Univ. Biochar Research Center"
            />
          </div>
          <div className="flex space-x-4">
            <MajorCustomerItem
              imgSrc="/img/majorCustomers/apru.jpg"
              imgAlt="APRU"
              title="APRU Sustainable Waste Management"
            />
            <MajorCustomerItem imgSrc="/img/majorCustomers/posco.jpg" imgAlt="POSCO" title="POSCO Liaison Center" />
            <MajorCustomerItem
              imgSrc="/img/majorCustomers/nature_conf.jpg"
              imgAlt="Nature Conferences"
              title="Partner of Nature conference in Korea"
            />
          </div>
        </div>
      </section>
      <section id="contact-section" className="bg-gray-100 py-16 pb-48">
        <div className="m-auto max-w-screen-lg">
          <h2 className="text-4xl font-bold text-center">Partner with us</h2>
          <div className="mt-2 text-gray-500 text-center">
            Get variety solutions and customized consulting report now.
          </div>
          <Formik<ContactFormValues>
            initialValues={{ firstName: '', lastName: '', institution: '', comment: '', email: '' }}
            validateOnChange={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await sendFeedback({
                  email: values.email,
                  content: JSON.stringify(values, null, 2),
                });
                setSubmitting(false);
                gtm.event(EventName.SEND_CONTACT, {
                  context: EventContext.INSTITUTION_ANALYTICS,
                  position: EventPosition.BODY,
                });
                alert(t('after_send_message'));
              } catch (err) {
                setSubmitting(false);
                alert('Something went to wrong. Please contact us via Pluto Homepage(https://www.pluto.im)');
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className="border p-8 rounded mt-8 w-full sm:w-2/3 m-auto bg-white">
                <div className="flex space-x-4">
                  <Field name="firstName" component={FormikInput} labelText="First Name" placeholder="Tyler" />
                  <Field name="lastName" component={FormikInput} labelText="Last Name" placeholder="Shin" />
                </div>
                <div className="mt-4">
                  <Field
                    type="email"
                    name="email"
                    component={FormikInput}
                    labelText="EMAIL"
                    placeholder="tyler@pluto.im"
                  />
                </div>
                <div className="mt-4">
                  <Field
                    name="institution"
                    component={FormikInput}
                    labelText="Affiliated Research Institution/Organization"
                    placeholder="POSTECH"
                  />
                </div>
                <div className="mt-4">
                  <Field
                    name="comment"
                    type="textarea"
                    labelText="Comments"
                    component={FormikInput}
                    placeholder="Write a comment ..."
                    style={{ height: '150px' }}
                    multiline
                  />
                </div>
                <div className="flex justify-end mt-8">
                  <Button elementType="button" type="submit" size="large" disabled={isSubmitting}>
                    Send
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default InstitutionAnalytics;
