import React, { FC } from 'react';
import { Button } from '@pluto_network/pluto-design-elements';
import { useSetRecoilState } from 'recoil';
import { globalDialogState } from '../../atom/globalModal';
import SampleUserLockModal from '../sampleUserLockModal';

const SampleTooltipContent: FC = () => {
  const setGlobalDialog = useSetRecoilState(globalDialogState);

  return (
    <div>
      <div>데이터 정밀 분석이 필요한 기능입니다.</div>
      <div>회원으로 가입 시 정밀 분석과 함께 Xenure의 모든 기능을 이용하실 수 있습니다.</div>
      <div className="gotcha flex justify-center mt-4">
        <Button elementType="button" size="small" onClick={() => setGlobalDialog({ content: <SampleUserLockModal /> })}>
          자세히 알아보기
        </Button>
      </div>
    </div>
  );
};

export default SampleTooltipContent;
