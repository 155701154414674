import React from 'react';
import { parse } from 'qs';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Header from 'components/header';
import MainSection from 'components/landing/mainSection';
import FeatureSection from 'components/landing/featureSection';
import UpcomingFeatureSection from 'components/landing/upcomingFeatureSection';
import TryBannerSection from 'components/landing/tryBannerSection';
import Footer from 'components/footer';
import AffiliationImageList from 'components/affiliationImageList';
import { useLogInDemo } from '../../hooks/useLoginDemo';

export const INVITED_EMAIL_COOKIE_KEY = 'i_e_c';

const AboutPage: React.FC = () => {
  const { mutate: loginDemo } = useLogInDemo();
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  if (queryParams.i) {
    Cookies.set(INVITED_EMAIL_COOKIE_KEY, queryParams.i, { expires: 7, path: '/' });
  }

  return (
    <>
      <Header onClickViewDemoButton={() => loginDemo()} />
      <MainSection onClickViewDemoButton={() => loginDemo()} />
      <FeatureSection />
      <AffiliationImageList />
      <UpcomingFeatureSection />
      <TryBannerSection />
      <Footer />
    </>
  );
};

export default AboutPage;
