import React, { FC } from 'react';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import FormikInput from '../common/formikInput';
import validateEmail from '../../helpers/validateEmail';
import { MINIMUM_PASSWORD_LENGTH } from '../../constants/auth';
import { useTranslation } from 'react-i18next';
import DebouncedAffiliationInput, { SelectableAffiliation } from '../affiliationInput';
import { Button } from '@pluto_network/pluto-design-elements';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useMutation, useQueryClient } from 'react-query';
import { createSampleUser, SignInResult } from '../../api/auth';
import gtm, { EventContext, EventName, EventPosition } from '../../helpers/gtm';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  affiliation: {
    affiliationId: string;
    affiliationName: string;
  };
  publicationList: string;
}

const SampleUserSignUpForm: FC<{ onSignUp: () => void }> = ({ onSignUp }) => {
  const { user } = useCurrentUser();
  const { t } = useTranslation(['sign_up_page']);
  const queryClient = useQueryClient();

  const { mutateAsync: signUp } = useMutation('/auth/login', createSampleUser, {
    onSuccess: (user) => {
      const prevSignInResult = queryClient.getQueryData<SignInResult>('/auth/login');
      queryClient.setQueryData<SignInResult>('/auth/login', { ...prevSignInResult!, user });
      gtm.event(EventName.SIGN_UP, {
        context: EventContext.SAMPLE_REPORT,
        position: EventPosition.MODAL,
        method: 'sample',
      });
      onSignUp();
    },
    onError: (err: any) => {
      const errorResponse = err?.response?.data.error;
      gtm.event(EventName.SIGN_UP_FAIL, {
        context: EventContext.SAMPLE_REPORT,
        position: EventPosition.MODAL,
        reason: errorResponse?.message || err,
      });
      alert(errorResponse?.message || err);
    },
  });

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!validateEmail(values.email)) {
      errors.email = t('email_validate_error');
    }

    if (!values.password || values.password.length < MINIMUM_PASSWORD_LENGTH) {
      errors.password = t('password_validate_error', { count: MINIMUM_PASSWORD_LENGTH });
    }

    if (!values.confirmPassword || values.confirmPassword.length < MINIMUM_PASSWORD_LENGTH) {
      errors.confirmPassword = t('password_validate_error', { count: MINIMUM_PASSWORD_LENGTH });
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t('confirm_password_validate_error');
    }

    if (!values.affiliation.affiliationName) {
      errors.affiliation = {
        affiliationName: t('affiliation_validate_error'),
      };
    }

    return errors;
  };

  if (!user) return null;

  return (
    <Formik<FormValues>
      initialValues={{
        email: user.email,
        password: '',
        confirmPassword: '',
        affiliation: { affiliationId: user.affiliation_id, affiliationName: user.affiliation_name },
        publicationList: '',
      }}
      onSubmit={async ({ email, password, affiliation, publicationList }, { setSubmitting }) => {
        try {
          await signUp({
            affiliationId: affiliation.affiliationId,
            affiliationName: affiliation.affiliationName,
            firstName: user.first_name,
            lastName: user.last_name,
            email,
            password,
            publicationList,
          });
        } catch {
          setSubmitting(false);
        }
      }}
      validate={validateForm}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <Form className="mt-8">
          <div>
            <label className="block mb-2">{t('general:email')}</label>
            <Field
              name="email"
              type="email"
              component={FormikInput}
              placeholder={t('email_placeholder')}
              leadingIcon={<i className="dripicons-mail" />}
            />
          </div>
          <div className="mt-4 flex space-x-4">
            <div>
              <label className="block mb-2">{t('general:password')}</label>
              <Field
                name="password"
                type="password"
                component={FormikInput}
                placeholder={t('general:password')}
                leadingIcon={<i className="dripicons-lock" />}
              />
            </div>
            <div>
              <label className="block mb-2">{t('confirm_password')}</label>
              <Field
                name="confirmPassword"
                type="password"
                component={FormikInput}
                placeholder={t('confirm_password')}
                leadingIcon={<i className="dripicons-lock" />}
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="block mb-2">{t('current_affiliation')}</label>
            <Field name="affiliation">
              {({ form }: FieldProps<{ affiliationId: string; affiliationName: string }, FormValues>) => {
                return (
                  <DebouncedAffiliationInput
                    initialAffiliation={{
                      affiliation_id: user?.affiliation_id,
                      keyword: user?.affiliation_name,
                      type: 'AFFILIATION',
                    }}
                    hasError={!!form.errors.affiliation?.affiliationName && !!form.touched.affiliation?.affiliationName}
                    errorMsg={form.errors.affiliation?.affiliationName}
                    onBlur={() => form.setFieldTouched('affiliation.affiliationId')}
                    onChange={(value) => form.setFieldValue('affiliation', { affiliationName: value })}
                    onSelect={(affiliation: SelectableAffiliation) => {
                      form.setFieldValue('affiliation', {
                        affiliationId: affiliation.affiliation_id,
                        affiliationName: affiliation.keyword,
                      });
                    }}
                  />
                );
              }}
            </Field>
          </div>
          <div className="mt-4">
            <label className="block mb-2">{t('pulication_list_or_url')}</label>
            <Field
              name="publicationList"
              type="textarea"
              component={FormikInput}
              placeholder={'해당 리스트를 참고해 추후 Xenure AI 엔진이 논문 목록을 정제합니다.'}
              className="h-32"
              multiline
            />
          </div>
          <div className="gotcha mt-8">
            <Button size="large" type="submit" elementType="button" disabled={isSubmitting} fullWidth>
              <span>회원 가입 신청</span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SampleUserSignUpForm;
