import React, { FC } from 'react';
import RoundButton from '../roundButton';
import { useTranslation } from 'react-i18next';
import { globalDialogState, INITIAL_GLOBAL_DIALOG_STATE } from '../../atom/globalModal';
import InvitationModalContent from '../invitationModal';
import { useSetRecoilState } from 'recoil';
import palette from '../../styles/palette';

const InviteOtherUser: FC = () => {
  const { t } = useTranslation(['top_banner', 'general']);
  const setGlobalDialog = useSetRecoilState(globalDialogState);
  const toggle = () =>
    setGlobalDialog((prev) => {
      if (!!prev.content) return INITIAL_GLOBAL_DIALOG_STATE;
      return { ...prev, content: <InvitationModalContent />, size: 'lg' };
    });

  return (
    <>
      <span style={{ fontSize: '14px', color: palette.gray800 }}>{t('invite_sentence_1')}</span>
      <span style={{ marginLeft: '16px' }}>
        <RoundButton style={{ fontSize: '12px', backgroundColor: palette.main_blue0, color: 'white' }} onClick={toggle}>
          <i className="mdi mdi-share mr-2" />
          {t('general:invite')}
        </RoundButton>
      </span>
    </>
  );
};

export default InviteOtherUser;
