import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import { useCurrentUser } from 'hooks/useCurrentUser';
import LocationListener from 'components/locationListener';
import SignUpPage from 'pages/signUp';
import GlobalModal from 'components/globalModal';
import JournalRankShow from 'pages/journalRankShow';
import ReportManager from '../components/reportManager';
import Fallback from 'components/fallback';
import InstitutionAnalytics from '../pages/institutionAnalytics';
import SideBarLayout from 'components/layout/sidebarLayout';

const Overview = lazy(() => import('pages/overview'));
const Publications = lazy(() => import('pages/publications'));
const PublicationShow = lazy(() => import('pages/publicationShow'));
const JournalShow = lazy(() => import('pages/journalShow'));
const PersonalAnalytics = lazy(() => import('pages/personalAnalytics'));
const CitationAnalytics = lazy(() => import('pages/citationAnalytics'));
const WeeklyRecommendationPage = lazy(() => import('pages/weeklyRecommendation'));
const ResearchNetwork = lazy(() => import('pages/researchNetwork'));
const Setting = lazy(() => import('pages/setting'));
const About = lazy(() => import('pages/about'));
const JournalRanks = lazy(() => import('pages/journalRank'));
const ResetPassword = lazy(() => import('pages/resetPassword'));
const EmailSetting = lazy(() => import('pages/emailSetting'));
const UserEmailNotificationSetting = lazy(() => import('pages/userEmailNotificationSetting'));
const LandingPage = lazy(() => import('pages/landing'));
const InviteSignUp = lazy(() => import('pages/signUp/inviteSignUp'));

const privateRouteMap: RouteProps[] = [
  {
    children: (
      <SideBarLayout>
        <JournalRankShow />
      </SideBarLayout>
    ),
    exact: true,
    path: '/journal-rank/:category_id',
  },
  {
    children: (
      <SideBarLayout>
        <JournalRanks />
      </SideBarLayout>
    ),
    exact: true,
    path: '/journal-rank',
  },
  {
    children: (
      <SideBarLayout>
        <ResearchNetwork />
      </SideBarLayout>
    ),
    exact: true,
    path: '/research/network',
  },
  {
    children: (
      <SideBarLayout>
        <PersonalAnalytics />
      </SideBarLayout>
    ),
    exact: true,
    path: '/analytics/personal',
  },
  {
    children: (
      <SideBarLayout>
        <PublicationShow />
      </SideBarLayout>
    ),
    exact: true,
    path: '/publications/:publication_id',
  },
  {
    children: (
      <SideBarLayout>
        <Publications />
      </SideBarLayout>
    ),
    exact: true,
    path: '/publications',
  },
  {
    children: (
      <SideBarLayout>
        <Overview />
      </SideBarLayout>
    ),
    exact: true,
    path: '/overview',
  },
  {
    children: (
      <SideBarLayout>
        <Setting />
      </SideBarLayout>
    ),
    exact: true,
    path: '/setting',
  },
  {
    children: (
      <SideBarLayout>
        <CitationAnalytics />
      </SideBarLayout>
    ),
    exact: true,
    path: '/analytics/citation',
  },
  {
    children: (
      <SideBarLayout>
        <WeeklyRecommendationPage />
      </SideBarLayout>
    ),
    exact: true,
    path: '/recommendation/weekly',
  },
  {
    children: (
      <SideBarLayout>
        <JournalShow />
      </SideBarLayout>
    ),
    exact: true,
    path: '/journals/:journal_id',
  },
  {
    children: (
      <SideBarLayout>
        <UserEmailNotificationSetting />
      </SideBarLayout>
    ),
    exact: true,
    path: '/user-email-setting',
  },
];

const routeMap: RouteProps[] = [
  {
    path: '/',
    exact: true,
    children: <About />,
  },
  {
    path: '/sign-up/invite',
    exact: true,
    children: <InviteSignUp />,
  },
  {
    path: '/sign-up',
    exact: true,
    children: <SignUpPage />,
  },
  {
    path: '/users/reset-password',
    exact: true,
    children: <ResetPassword />,
  },
  {
    path: '/email-setting',
    exact: true,
    children: <EmailSetting />,
  },
  {
    path: '/landing',
    exact: true,
    children: <LandingPage />,
  },
  {
    path: '/institution-analytics',
    children: (
      <SideBarLayout>
        <InstitutionAnalytics />
      </SideBarLayout>
    ),
    exact: true,
  },
  {
    children: <h1>Page Not Found</h1>,
  },
];

const PrivateRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
  const { user, isLoading } = useCurrentUser();
  const location = useLocation();

  if (isLoading) return null;

  const allowed = user?.status !== undefined && user?.status !== 'PENDING';
  return (
    <Route
      {...routeProps}
      render={() => (allowed ? children : <Redirect to={{ pathname: '/', search: location.search }} />)}
    />
  );
};

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <LocationListener />
        <GlobalModal />
      </Suspense>
      <ReportManager />
      <Suspense fallback={<Fallback />}>
        <Switch>
          {privateRouteMap.map((route) => (
            <PrivateRoute {...route} key={String(route.path)} />
          ))}
          {routeMap.map((route) => (
            <Route {...route} key={String(route.path)} />
          ))}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
