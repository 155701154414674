import React from 'react';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import responsive from 'styles/responsive';
import { useTranslation } from 'react-i18next';

const UpcomingFeatureContainer = styled.section`
  background-color: ${palette.gray30};
`;

const UpcomingFeatureWrapper = styled.div`
  max-width: 1260px;
  margin: 0 auto;
  padding: 120px 0;

  @media (max-width: ${responsive.tablet_width}) {
    padding: 60px 0;
  }
`;

const UpcomingFeatureTitle = styled.div`
  line-height: 1.25;
  font-weight: 700;
  font-size: 28px;
  color: ${palette.black0};
  text-align: center;
`;

const UpcomingFeatureList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;

  @media (max-width: ${responsive.tablet_width}) {
    flex-direction: column;
    align-items: center;
  }
`;

const UpcomingFeatureItem = styled.div`
  width: 250px;
  text-align: center;

  & + & {
    margin: 0 0 0 16px;
    border-left: 1px solid ${palette.gray400};
    padding: 0 0 0 16px;
  }

  @media (max-width: ${responsive.tablet_width}) {
    & + & {
      margin: 32px 0 16px 0;
      padding: 16px 0;
      border-left: none;
      border-top: 1px solid ${palette.gray400};
    }
  }
`;

const UpcomingFeatureItemTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${palette.black0};
  margin-top: 16px;
`;

const UpcomingFeatureItemIcon = styled.i`
  font-size: 70px;
  color: ${palette.black0};
`;

const UpcomingFeatureSection: React.FC = () => {
  const { t } = useTranslation('about_page');
  return (
    <UpcomingFeatureContainer>
      <UpcomingFeatureWrapper>
        <UpcomingFeatureTitle>{t('upcoming_feature_title')}</UpcomingFeatureTitle>
        <UpcomingFeatureList>
          <UpcomingFeatureItem>
            <UpcomingFeatureItemIcon className="uil-lightbulb-alt" />
            <UpcomingFeatureItemTitle>{t('upcoming_feature_1')}</UpcomingFeatureItemTitle>
          </UpcomingFeatureItem>
          <UpcomingFeatureItem>
            <UpcomingFeatureItemIcon className="uil-analysis" />
            <UpcomingFeatureItemTitle>{t('upcoming_feature_2')}</UpcomingFeatureItemTitle>
          </UpcomingFeatureItem>
          <UpcomingFeatureItem>
            <UpcomingFeatureItemIcon className="uil-fast-mail" />
            <UpcomingFeatureItemTitle>{t('upcoming_feature_3')}</UpcomingFeatureItemTitle>
          </UpcomingFeatureItem>
        </UpcomingFeatureList>
      </UpcomingFeatureWrapper>
    </UpcomingFeatureContainer>
  );
};

export default UpcomingFeatureSection;
