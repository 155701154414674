import React, { FC, useEffect } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useRecoilState } from 'recoil';
import { reportState } from '../../atom/reports';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { getPermittedReports } from 'api/user';

const ReportManager: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { pri: preSelectedReportId } = parse(location.search, { ignoreQueryPrefix: true });
  const { user, is_admin } = useCurrentUser();
  const [reports, setReports] = useRecoilState(reportState);

  useEffect(() => {
    if (!user) return;
    if (is_admin) return;

    if (preSelectedReportId) {
      getPermittedReports()
        .then((reports) => {
          console.log(reports);
          const targetReport = reports.find((report) => report.id.toString() === preSelectedReportId.toString());
          if (targetReport) {
            setReports({ report: targetReport });
          } else {
            throw new Error('볼 수 있는 Report가 아닙니다. 관리자에게 문의해주세요.');
          }
        })
        .catch((err) => {
          console.error(err);
          alert('에러가 발생했습니다. 관리자에게 문의해주세요.');
          history.push('/');
        });
    }
  }, [user, setReports, is_admin, preSelectedReportId, history, reports.report]);

  return null;
};

export default ReportManager;
