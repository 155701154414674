import { User } from '../model/user';

declare global {
  interface Window {
    dataLayer?: DataLayerObject[];
  }
}

interface DataLayerObject {
  event?: EventName;
  data?: EventData;
  user?: UserProperty;
}

interface EventData {
  context?: EventContext;
  position?: EventPosition;

  [key: string]: any;
}

interface UserProperty {
  id: number;
  affiliation_id?: string;
  affiliation_name?: string;
}

export enum EventName {
  SIGN_UP_VIA_FAST_TRACK_INVITE = 'sign_up_via_fast_track_invite',
  SET_USER_PROPERTY = 'set_user_property',
  LOGIN = 'login',
  LOGOUT = 'logout',
  LOGIN_DEMO = 'login_demo',
  LOGOUT_DEMO = 'logout_demo',
  SIGN_UP = 'sign_up',
  SIGN_UP_FAIL = 'sign_up_fail',
  CLICK_REFRESH_DATA = 'click_refresh_data',
  COPY_URL = 'copy_url',
  UPDATE_PASSWORD = 'update_password',
  SEND_CONTACT = 'send_contact',
  SAMPLE_START = 'sample_start',
  SAMPLE_FORM = 'sample_form',
  TOGGLE_NOTIFICATION = 'toggle_notification',
  DOWNLOAD_PUBLICATION = 'download_publication',
}

export enum EventContext {
  INSTITUTION_ANALYTICS = 'institution_analytics',
  SEND_INVITATION = 'send_invitation',
  UPDATE_PASSWORD = 'update_password',
  SIGN_UP = 'sign_up',
  SAMPLE_REPORT = 'sample_report',
  MANAGE_NOTIFICATION = 'manage_notification',
  EXPORT_PUBLICATION = 'export_publication',
}

export enum EventPosition {
  HEADER = 'header',
  FOOTER = 'footer',
  SIDEBAR = 'sidebar',
  MODAL = 'modal',
  BODY = 'body',
}

function getDataLayer() {
  return (window.dataLayer = window.dataLayer || []);
}

function push(data: DataLayerObject) {
  getDataLayer().push(data);
}

function event(event: EventName, data?: EventData) {
  push({ event, data });
}

function data(data: EventData) {
  push({ data });
}

function setUserProperty(user?: User) {
  if (!user || !Number(user.id)) {
    return;
  }

  const userProperty: UserProperty = {
    id: Number(user.id),
    affiliation_id: user.affiliation_id,
    affiliation_name: user.affiliation_name,
  };

  push({ event: EventName.SET_USER_PROPERTY, user: userProperty });
}

const gtm = {
  event,
  data,
  setUserProperty,
};

export default gtm;
