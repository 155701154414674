import React, { FC } from 'react';
import HoverPictureItem from '../hoverPictureItem';

const FeatureItem: FC<{ title: string; imgSrc: string; alt: string; content: JSX.Element; reverse?: boolean }> = ({
  title,
  imgSrc,
  alt,
  content,
  reverse,
}) => {
  return (
    <li className="p-4 border rounded-md bg-white justify-between">
      <div className="text-xl font-bold mb-4">{title}</div>
      <div className="flex space-x-4">
        {!reverse && (
          <div className="flex-shrink-0">
            <HoverPictureItem src={imgSrc} alt={alt} className="max-w-sm" />
          </div>
        )}
        <div className="p-2">
          <ul className="text-gray-700">{content}</ul>
        </div>
        {reverse && (
          <div className="flex-shrink-0">
            <HoverPictureItem src={imgSrc} alt={alt} className="max-w-sm" />
          </div>
        )}
      </div>
    </li>
  );
};

export default FeatureItem;
