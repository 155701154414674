import { globalDialogState } from 'atom/globalModal';
import SampleUserLockModal, { SampleUserLockModalStep } from 'components/sampleUserLockModal';
import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';

const LockUserContent: FC = () => {
  const setGlobalDialog = useSetRecoilState(globalDialogState);

  return (
    <div className="flex text-gray-500 space-x-4 items-center">
      <div>
        지금 바로 <b className="font-medium">회원으로 가입</b>하고 Xenure의 모든 기능을 이용해보세요.
      </div>
      <button
        onClick={() => setGlobalDialog({ content: <SampleUserLockModal initialStep={SampleUserLockModalStep.FORM} /> })}
        className="p-2 border rounded-md bg-blue-500 text-white transform active:translate-y-px"
      >
        회원 가입 신청
      </button>
    </div>
  );
};

export default LockUserContent;
