import React, { FC, useState } from 'react';
import { Button } from '@pluto_network/pluto-design-elements';
import styled from '@emotion/styled';
import { InputField } from '@pluto_network/pluto-design-elements';
import { useSetRecoilState } from 'recoil';
import { globalDialogState } from 'atom/globalModal';
import { useTranslation } from 'react-i18next';
import palette from 'styles/palette';
import ModalContent from '../modalContent';
import { requestResetPasswordEmail } from '../../api/auth';
import { AxiosError } from 'axios';

const GuideText = styled.div`
  font-size: 16px;
  color: ${palette.gray800};
  line-height: 1.75;
`;

const ResetPasswordModal: FC = () => {
  const { t } = useTranslation(['reset_password_modal', 'general']);
  const setGlobalDialog = useSetRecoilState(globalDialogState);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmitEmail() {
    try {
      setIsLoading(true);
      await requestResetPasswordEmail(email);
      alert(t('success_text'));
      setGlobalDialog({ content: null });
    } catch (err) {
      alert((err as AxiosError).response?.data?.error?.message || (err as Error).message);
    }
    setIsLoading(false);
  }

  return (
    <>
      <ModalContent
        headline={<div>FORGOT YOUR PASSWORD?</div>}
        footer={
          <div className="space-x-4">
            <Button color="gray" elementType="button" onClick={() => setGlobalDialog({ content: null })}>
              <span>Close</span>
            </Button>
            <Button
              color="blue"
              isLoading={isLoading}
              disabled={isLoading}
              elementType="button"
              onClick={handleSubmitEmail}
            >
              <span>Send</span>
            </Button>
          </div>
        }
      >
        <GuideText style={{ marginBottom: '16px' }}>{t('guide_text')}</GuideText>
        <InputField
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder={t('general:email')}
          leadingIcon={<i className="dripicons-mail" />}
          variant="underlined"
        />
      </ModalContent>
    </>
  );
};

export default ResetPasswordModal;
