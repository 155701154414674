import Cross from 'components/icons/cross';
import React, { CSSProperties, FC } from 'react';
import { useSetRecoilState } from 'recoil';
import { globalDialogState, INITIAL_GLOBAL_DIALOG_STATE } from '../../atom/globalModal';

interface Props {
  headline: JSX.Element;
  footer?: JSX.Element;
  wrapperStyle?: CSSProperties;
}

const ModalContent: FC<Props> = ({ headline, footer, wrapperStyle, children }) => {
  const setGlobalDialogState = useSetRecoilState(globalDialogState);

  return (
    <div
      className="relative inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle max-w-10/12 sm:max-w-7/12 max-h-70-screen"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      style={wrapperStyle}
    >
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start flex justify-center">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3
              className="text-lg leading-6 font-medium text-gray-900 flex justify-between space-x-4"
              id="modal-headline"
            >
              {headline}
              <Cross
                className="w-7 h-7 top-4 right-4 text-gray-500 cursor-pointer"
                onClick={() => setGlobalDialogState(INITIAL_GLOBAL_DIALOG_STATE)}
              />
            </h3>
            <div className="mt-2">{children}</div>
          </div>
        </div>
      </div>
      {!!footer && <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">{footer}</div>}
    </div>
  );
};

export default ModalContent;
