import React, { FC, useState } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import { Formik, Form, Field } from 'formik';
import FormikInput from 'components/common/formikInput';
import { Button } from '@pluto_network/pluto-design-elements';
import { updatePublicationSource } from 'api/auth';
import { useTranslation } from 'react-i18next';
import ModalContent from '../modalContent';
import { AxiosError } from 'axios';

type UpdatePubSourceFormValue = {
  source: string;
};

type Props = {
  publicationSource: string;
  onToggle: () => void;
};

const FieldWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const LabelWrapper = styled.label`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.5;
  color: ${palette.gray700};
  padding-left: 10px;
  width: 100%;
`;

const UpdatePubSourceModal: FC<Props> = ({ publicationSource, onToggle }) => {
  const { t } = useTranslation(['sign_in_form', 'sign_up_page']);
  const { user: currentUser } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);

  if (!currentUser) return null;

  async function handleSubmit({ source }: UpdatePubSourceFormValue) {
    setIsLoading(true);
    try {
      await updatePublicationSource(source);
      setIsLoading(false);
      onToggle();
    } catch (err) {
      alert((err as AxiosError).response?.data?.error?.message || (err as Error).message);
      setIsLoading(false);
    }
  }

  return (
    <>
      <ModalContent headline={<div>{t('modify_pub_list_title')}</div>}>
        <Formik
          initialValues={{
            source: publicationSource,
          }}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Form>
            <FieldWrapper>
              <LabelWrapper>{t('modify_pub_list_label')}</LabelWrapper>
              <Field
                name="source"
                type="textarea"
                component={FormikInput}
                placeholder={t('sign_up_page:pulication_list_or_url_placeholder')}
                style={{ height: '320px' }}
                multiline
              />
            </FieldWrapper>
            <Button size="large" type="submit" elementType="button" fullWidth isLoading={isLoading}>
              <span>수정하기</span>
            </Button>
          </Form>
        </Formik>
      </ModalContent>
    </>
  );
};

export default UpdatePubSourceModal;
