import React, { FC, useState } from 'react';
import classNames from 'classnames';

const TooltipV2: FC<{ tooltipContent: JSX.Element; tooltipClassName?: string; reverse?: boolean }> = ({
  tooltipContent,
  tooltipClassName,
  reverse,
  children,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div className="relative" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {children}
      <div
        className={classNames(
          'absolute p-3 bg-gray-600 text-white w-64 right-0 text-center text-xs rounded-md z-10 whitespace-pre-wrap shadow-md',
          { hidden: !isHover },
          { block: isHover },
          { 'bottom-full': !reverse },
          { 'top-full': reverse },
          tooltipClassName
        )}
      >
        {tooltipContent}
      </div>
    </div>
  );
};
export default TooltipV2;
