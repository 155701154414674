import React, { FC, SVGProps } from 'react';

const SquareLogo: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="128" fill="none" viewBox="0 0 155 128" {...props}>
      <path fill="#536DFE" d="M155 0l-49.145 64-48.95 64H.702l49.145-64 48.95-64H155z"></path>
      <path
        fill="#C0CFFF"
        d="M72.44 16.858L44.163 53.703 2.716.001h56.228L72.44 16.858zM83.01 111.142l28.276-36.845L152.734 128H96.506L83.01 111.142z"
      ></path>
      <path
        fill="#ABC0FF"
        d="M44.247 53.703L72.44 16.857 58.984 0 44.247 53.703zM111.455 74.297l-28.443 36.846L96.587 128l14.868-53.703z"
      ></path>
    </svg>
  );
};

export default SquareLogo;
