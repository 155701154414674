import Fetcher from './fetcher';
import { IfTrendGraph } from 'model/ifTrendGraph';
import { CitationTrendGraph } from 'model/citationTrendGraph';
import { GraphStats, Overview } from 'model/overview';
import { Authorship } from '../types/general';
import { UserCandidateSignupForm } from '../model/userCandidateSignUpForm';
import { SignInResult } from './auth';
import { Report } from 'model/report';

export async function getIfTrendGraphData(reportId: string | number) {
  const res = await Fetcher.instance.get(`/reports/${reportId}/if-trend`);

  return res.data.data as IfTrendGraph[];
}

export async function getCitationTrendGraphData(reportId: string | number) {
  const res = await Fetcher.instance.get(`/reports/${reportId}/citation-trend`);

  return res.data.data as CitationTrendGraph[];
}

interface OverviewDataParams {
  reportId: string | number;
  fd?: string;
  td?: string;
}

export async function getOverviewData({ reportId, fd, td }: OverviewDataParams) {
  const res = await Fetcher.instance.get(`/reports/${reportId}/overview`, { params: { fd, td } });

  return res.data.data as Overview;
}

export async function getOverviewGraphStats(reportId: string | number, as?: Authorship, fd?: string, td?: string) {
  const res = await Fetcher.instance.get<{ data: GraphStats[] }>(`/reports/${reportId}/stats/graph`, {
    params: { as: as || 'ALL', fd, td },
  });

  return res.data.data;
}

export async function getSimpleSignUpUserCandidate({ token }: { token: string }) {
  const res = await Fetcher.instance.get<{ data: UserCandidateSignupForm }>(`/users/candidates?token=${token}`);
  return res.data.data;
}

export async function getPermittedReports() {
  const res = await Fetcher.instance.get<{ data: Report[] }>(`/users/me/permitted-reports`);
  return res.data.data;
}

interface CreateUserFromConsultingReportParams {
  token: string;
  affiliationId: string;
  affiliationName: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  publicationSource: string;
}

export async function createUserFromConsultingReport({
  token,
  affiliationId,
  affiliationName,
  email,
  firstName,
  lastName,
  publicationSource,
  password,
}: CreateUserFromConsultingReportParams) {
  const res = await Fetcher.instance.post<{ data: SignInResult }>(`/users/consulting?token=${token}`, {
    affiliation_id: affiliationId,
    affiliation_name: affiliationName,
    first_name: firstName,
    last_name: lastName,
    publication_source: publicationSource,
    email,
    password,
  });

  Fetcher.setAccessToken(res.data.data.token);

  return res.data.data;
}
