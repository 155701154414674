import React, { FC } from 'react';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import responsive from 'styles/responsive';
import Logo from 'components/icons/logo';

const FooterContainer = styled.footer`
  background-color: white;
  font-size: 14px;
  box-shadow: inset 0 2px 8px -2px rgba(0, 0, 0, 0.07);
`;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px 16px;
`;

const FooterBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${responsive.tablet_width}) {
    flex-direction: column;
  }
`;

const FooterLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: ${palette.gray700};

  @media (max-width: ${responsive.tablet_width}) {
    font-size: 13px;
    div + div {
      margin-top: 8px;
    }
  }
`;

const FooterRightBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.6;
  color: ${palette.gray800};
  font-weight: 500;

  @media (max-width: ${responsive.tablet_width}) {
    font-size: 14px;
    margin: 16px 0;
  }
`;

const FooterLink = styled.a`
  display: block;

  & + & {
    margin-top: 8px;
  }

  @media (max-width: ${responsive.tablet_width}) {
    & + & {
      margin-top: 4px;
    }
  }
`;

const Footer: FC = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterBoxWrapper>
          <FooterLeftBox>
            <Logo style={{ width: '120px', height: '30px' }} />
          </FooterLeftBox>
          <FooterRightBox>
            <div>
              <FooterLink href="https://www.pluto.im" target="_blank" rel="noopener noreferrer">
                About us
              </FooterLink>
              <FooterLink href="https://www.scinapse.io/terms-of-service" target="_blank" rel="noopener noreferrer">
                Terms of service
              </FooterLink>
              <FooterLink href="https://www.scinapse.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                Privacy policy
              </FooterLink>
            </div>
          </FooterRightBox>
        </FooterBoxWrapper>
        <FooterLeftBox>
          <div>419, Teheran-ro, Gangnam-gu, Seoul, 06160, Republic of Korea</div>
          <div>{`© ${new Date().getFullYear()} Pluto, Inc. All rights reserved.`}</div>
        </FooterLeftBox>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
