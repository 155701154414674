import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '@pluto_network/pluto-design-elements';
import styled from '@emotion/styled';
import palette from 'styles/palette';
import { globalDialogState } from 'atom/globalModal';
import { useTranslation } from 'react-i18next';
import ModalContent from '../modalContent';

export const DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY = 'd_g_m_c_c';

const GuideTitle = styled.div`
  font-size: 20px;
  color: ${palette.black0};
  font-weight: 500;
  margin-bottom: 32px;

  b {
    color: ${palette.main_blue1};
    font-weight: 500;
  }
`;

const GuideText = styled.div`
  font-size: 16px;
  color: ${palette.gray800};
`;

const DemoGuideModal: FC = () => {
  const { t } = useTranslation(['demo_guide_dialog', 'general']);
  const [_, setGlobalDialog] = useRecoilState(globalDialogState);
  const toggle = () => setGlobalDialog({ content: null });

  return (
    <ModalContent
      headline={
        <div className="text-center">
          <GuideTitle>{t('title')}</GuideTitle>
        </div>
      }
      footer={
        <Button color="blue" elementType="button" onClick={toggle}>
          <span>{t('general:start')}</span>
        </Button>
      }
    >
      <GuideText dangerouslySetInnerHTML={{ __html: t('guide_text') }} />
    </ModalContent>
  );
};
export default DemoGuideModal;
