import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import palette from '../../styles/palette';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import SelectLocaleButton from '../selectLocaleButton';
import AdminSection from '../admin/adminSection';
import DemoContent from './demoContent';
import InviteOtherUser from './inviteOtherUser';
import classNames from 'classnames';
import LockUserContent from './lockUserContent';
import SquareLogo from 'components/icons/squareLogo';
import { parse } from 'qs';
import { useRecoilState } from 'recoil';
import { reportState } from 'atom/reports';

const DEFAULT_HEADER_BACKGROUND_START_HEIGHT = 64;
let ticking = false;

const SignedHeader: FC = () => {
  const location = useLocation();
  const { pri: preSelectedReportId } = parse(location.search, { ignoreQueryPrefix: true });
  const { user, is_admin, is_demo } = useCurrentUser();
  const isNormalUser = !is_demo && !is_admin;
  const isSampleUser = user.status === 'SAMPLE';
  const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  const [isTop, setIsTop] = useState(scrollTop < DEFAULT_HEADER_BACKGROUND_START_HEIGHT);
  const wrapperRef = useRef<HTMLElement | null>(null);
  const [currentReport] = useRecoilState(reportState);

  useEffect(() => {
    const updateIsTopState = () => {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const headerHeight = wrapperRef.current?.offsetHeight ?? DEFAULT_HEADER_BACKGROUND_START_HEIGHT;
      setIsTop(scrollTop < headerHeight);
      ticking = false;
    };

    const handleScrollEvent = () => {
      if (!ticking) {
        requestAnimationFrame(updateIsTopState);
      }

      ticking = true;
    };

    window.addEventListener('scroll', handleScrollEvent, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  if (!user) return null;

  return (
    <nav
      className={classNames('h-16 bg-white z-20 flex top-0 left-0 right-0 border-b sticky', {
        'shadow-sm border-opacity-0': !isTop,
        'border-b relative': isTop,
      })}
      ref={wrapperRef}
    >
      <Link to="/" className="flex items-center p-4">
        <SquareLogo className="w-10 h-10 mr-2" />
        <div className="whitespace-nowrap text-2xl tracking-tight font-medium text-gray-500">Xenure</div>
      </Link>
      <div className="flex-1 flex ml-32 px-8">
        <div className="flex items-center w-full">
          {is_admin && <AdminSection />}
          {is_demo && <DemoContent />}
          {preSelectedReportId && (
            <div className="whitespace-pre-wrap">
              {'현재 '}
              <b>{`${currentReport.report?.name}`}</b>
              {'님의 정보를 열람중입니다.'}
            </div>
          )}
          {isNormalUser && !isSampleUser && !preSelectedReportId && <InviteOtherUser />}
          {isSampleUser && <LockUserContent />}
        </div>
        <div>
          <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <SelectLocaleButton
              btnStyle={{
                textAlign: 'left',
                background: 'none',
                border: `1px solid ${palette.gray300}`,
                padding: '8px 12px',
                fontSize: '14px',
                color: palette.black0,
              }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SignedHeader;
