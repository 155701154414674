import { atom } from 'recoil';

type categoryId = string;
type LastJournalRankSelectedYearState = Map<categoryId, number>;

export const INITIAL_LAST_JOURNAL_RANK_SELECTED_YEAR: LastJournalRankSelectedYearState = new Map();

export const lastJournalRankSelectedYear = atom<LastJournalRankSelectedYearState>({
  key: 'LastJournalRankSelectedYearState',
  default: INITIAL_LAST_JOURNAL_RANK_SELECTED_YEAR,
});
