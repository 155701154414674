import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { globalDialogState, INITIAL_GLOBAL_DIALOG_STATE } from 'atom/globalModal';

const GlobalModal: FC = () => {
  const [{ content }, setGlobalDialogState] = useRecoilState(globalDialogState);

  if (!content) return null;

  return (
    <div className="fixed z-20 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={() => setGlobalDialogState(INITIAL_GLOBAL_DIALOG_STATE)}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        {content}
      </div>
    </div>
  );
};

export default GlobalModal;
