import Fetcher from './fetcher';

interface SendFeedbackParams {
  content: string;
  email: string;
}

export async function sendFeedback({ content, email }: SendFeedbackParams) {
  const res = await Fetcher.instance.post(`/feedbacks`, { content, email });

  return res.data;
}
