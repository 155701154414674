import React, { FC } from 'react';
import { Button } from '@pluto_network/pluto-design-elements';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const Index: FC<Props> = ({ children, ...props }) => {
  return (
    <div className="relative group shadow-md border rounded-md overflow-hidden">
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img {...props} />
      <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100">
        <div className="bg-gray-900 opacity-0 absolute inset-0 group-hover:opacity-60 transition-opacity" />
        <Button elementType="anchor" size="large" className="z-10" href={props.src} target="_blank">
          See Detail
        </Button>
      </div>
    </div>
  );
};

export default Index;
