import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SAVED_SELECTED_LOCALE_KEY } from 'constants/locale';
import { useClickOutsideListener } from 'hooks/useClickoutsideListener';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const SelectLocaleButton: FC<{ btnStyle?: CSSProperties }> = ({ btnStyle }) => {
  const { i18n } = useTranslation();
  const [currentLocale, setCurrentLocale] = useState(i18n.language);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dom = useRef<HTMLDivElement>(null);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useClickOutsideListener({ dom: dom.current, cb: () => (dropdownOpen ? setDropdownOpen(false) : null) });

  async function handleClickLocale(locale: string) {
    await i18n.changeLanguage(locale);
    setCurrentLocale(locale);
    window.localStorage.setItem(SAVED_SELECTED_LOCALE_KEY, locale);
  }

  useEffect(() => {
    const locale = i18n.language;
    if (locale) {
      setCurrentLocale(locale);
      i18n.changeLanguage(locale).then(() => {
        window.localStorage.setItem(SAVED_SELECTED_LOCALE_KEY, locale);
      });
    }
  }, [i18n, i18n.language]);

  let buttonContent = (
    <span role="img" aria-label="English">
      🇰🇷
    </span>
  );

  if (currentLocale?.toLowerCase().includes('en')) {
    buttonContent = (
      <span role="img" aria-label="Korean">
        🇺🇸
      </span>
    );
  }

  return (
    <div ref={dom} className="relative" onClick={() => toggle()}>
      <div className="cursor-pointer" style={btnStyle}>
        {buttonContent}
      </div>
      {dropdownOpen && (
        <ul className="p-2 bg-white absolute right-0 border rounded mt-1">
          <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => handleClickLocale('ko')}>
            Korean
          </li>
          <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => handleClickLocale('en')}>
            English
          </li>
        </ul>
      )}
    </div>
  );
};

export default SelectLocaleButton;
