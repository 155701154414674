import React, { FC } from 'react';
import logo from 'assets/images/square_logo_black.png';
import SquareLogo from 'components/icons/squareLogo';

const MiniFooter: FC = () => {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-900 text-gray-200 py-2 px-5 flex justify-between text-sm z-30">
      <div className="flex space-x-2 items-center">
        <SquareLogo className="w-6 h-6" />
        <span>© {new Date().getFullYear()} Pluto, Inc.</span>
      </div>
      <div className="flex items-center">
        <ul className="flex space-x-8">
          <a href="https://www.pluto.im" target="_blank" className="hover:text-white" rel="noreferrer">
            <li>About</li>
          </a>
          <a href="https://www.pluto.im/solution" target="_blank" className="hover:text-white" rel="noreferrer">
            <li>Institution Consulting</li>
          </a>
          <a href="https://www.pluto.im/contact" target="_blank" className="hover:text-white" rel="noreferrer">
            <li>Contact</li>
          </a>
        </ul>
      </div>
    </footer>
  );
};

export default MiniFooter;
