import React, { FC, useRef, useState } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import classNames from 'classnames';
import { useClickOutsideListener } from '../../hooks/useClickoutsideListener';
import { useMutation, useQueryClient } from 'react-query';
import { signOut } from '../../api/auth';
import Cookies from 'js-cookie';
import { DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY } from '../demoGuideModal';
import gtm, { EventName } from '../../helpers/gtm';
import { Link, useHistory } from 'react-router-dom';

const UserDropdown: FC = () => {
  const { user, is_demo } = useCurrentUser();
  const isLockedUser = user?.status === 'SAMPLE' || user?.status === 'IN_TRANSITION';
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();

  const { mutateAsync: logout } = useMutation('/auth/login', signOut, {
    onSuccess: () => {
      Cookies.remove(DEMO_GUIDE_MODAL_CLOSE_COOKIE_KEY);
      queryClient.setQueryData('/auth/login', null);
      history.push('/');
      gtm.event(is_demo ? EventName.LOGOUT_DEMO : EventName.LOGOUT);
    },
  });

  useClickOutsideListener({ dom: wrapperRef.current, cb: () => (isOpen ? setIsOpen(false) : null) });

  if (!user) return null;

  return (
    <div className="relative border-t border-gray-600 hover:bg-gray-800">
      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a
        className="p-4 text-white font-bold flex items-center cursor-pointer flex justify-between"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>
          <span>{`${user!.first_name} ${user!.last_name}`}</span>
          <i
            className={classNames('uil ml-1 text-lg leading-none', {
              'uil-angle-down': isOpen,
              'uil-angle-up': !isOpen,
            })}
          />
        </div>
        <i className="text-xl leading-none dripicons-gear" />
      </a>
      <div
        className={classNames(
          'absolute bottom-full left-0 ml-4 rounded-md bg-gray-600 w-72 font-normal shadow-md border border-gray-500 cursor-pointer transition-appear-disappear',
          {
            'invisible opacity-0': !isOpen,
            'opacity-100 visible': isOpen,
          }
        )}
        ref={wrapperRef}
      >
        <div className="py-6 border-b border-gray-500">
          <div className="px-4">
            <div className="font-bold mb-1">{`${user!.first_name} ${user!.last_name}`}</div>
            <div className="text-gray-400 text-xs">{user!.affiliation_name}</div>
            <div className="text-gray-300 text-xs">{user!.email}</div>
          </div>
        </div>
        {!isLockedUser && !is_demo && (
          <ul className="py-2 border-b border-gray-500">
            <Link to="/setting" onClick={() => setIsOpen(false)}>
              <li className="text-gray-200 hover:bg-blue-600 hover:text-white">
                <div className="px-4 py-1">Change Password</div>
              </li>
            </Link>
            <Link className="gotcha" to="/user-email-setting" onClick={() => setIsOpen(false)}>
              <li className="text-gray-200 hover:bg-blue-600 hover:text-white">
                <div className="px-4 py-1">Email Notification</div>
              </li>
            </Link>
          </ul>
        )}
        <ul className="py-2">
          <li
            className="text-gray-200 hover:bg-blue-600 hover:text-white"
            onClick={async () => {
              await logout();
              setIsOpen(false);
            }}
          >
            <div className="px-4 py-1">Logout</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserDropdown;
