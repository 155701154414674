import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { formatDistanceToNow } from 'date-fns';
import { enUS, ko } from 'date-fns/locale';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SAVED_SELECTED_LOCALE_KEY } from 'constants/locale';

const isDev = process.env.NODE_ENV === 'development';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use({
    type: 'backend',
    read(language: 'ko' | 'en', namespace: any, callback: (errorValue: unknown, translations: any) => void) {
      import(`./locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    },
  })
  .init({
    backend: {
      backends: [LocalStorageBackend],
      backendOptions: [
        { prefix: 'i18n_res_', defaultVersion: 'latest', expirationTime: isDev ? 0 : 3 * 60 * 60 * 1000 /* 3 hours */ },
      ],
    },
    debug: false,
    load: 'languageOnly',
    fallbackLng: 'ko',
    keySeparator: false,
    cleanCode: true,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: SAVED_SELECTED_LOCALE_KEY,
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'fromNow') {
          return formatDistanceToNow(value, { locale: lng === 'ko' ? ko : enUS, addSuffix: true });
        }
        return value;
      },
    },
    react: { useSuspense: true },
  });

export default i18n;
