import React, { FC } from 'react';
import classNames from 'classnames';

const SidebarItem: FC<{ isActive: boolean; disabled?: boolean; isPreview?: boolean; isAdvanced?: boolean }> = ({
  isActive,
  isPreview,
  isAdvanced,
  children,
}) => {
  const hasLabel = isPreview || isAdvanced;
  return (
    <>
      <li
        className={classNames('relative flex items-center py-3 px-4 rounded cursor-pointer hover:bg-gray-800', {
          'bg-gray-900 text-white': isActive,
        })}
      >
        {hasLabel && (
          <label
            className={classNames(
              'absolute inline-block text-center text-xs px-2 py-1 rounded-lg leading-none -top-1.5 left-0',
              { 'bg-yellow-400 text-gray-800': isPreview },
              { 'bg-red-600 text-white': isAdvanced }
            )}
          >
            {isPreview ? 'Preview' : 'Advanced'}
          </label>
        )}
        {children}
      </li>
    </>
  );
};

export default SidebarItem;
