import React, { FC, useState } from 'react';
import ModalContent from '../modalContent';
import { globalDialogState, INITIAL_GLOBAL_DIALOG_STATE } from '../../atom/globalModal';
import { useSetRecoilState } from 'recoil';
import { Button } from '@pluto_network/pluto-design-elements';
import SampleUserSignUpForm from './sampleUserSignUpForm';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import gtm, { EventContext, EventName } from '../../helpers/gtm';

export enum SampleUserLockModalStep {
  GUIDE,
  FORM,
  FINISH,
}

export const FeatureLockedContent: FC<{ onClickSignUpBtn: () => void }> = ({ onClickSignUpBtn }) => {
  return (
    <div className="leading-relaxed mt-8 font-light text-lg">
      <div>
        <div>
          해당 기능을 사용하기 위해서는 <b className="font-medium">데이터 정밀 분석</b>이 필요합니다.
        </div>
        <div>
          <b className="font-medium">회원으로 가입</b>하시는 경우 데이터 정밀 분석이 진행되며, 아래와 같은 다양한 혜택을
          받으실 수 있습니다.
        </div>
      </div>
      <br />
      <ul className="list-disc list-inside">
        <li>Xenure만의 고유한 AI 데이터 분석 알고리즘으로 완벽히 정제된 데이터를 제공합니다.</li>
        <li>제한되었던 모든 기능을 무제한으로 이용하실 수 있습니다.</li>
        <li>연구 실적 업데이트나 신규 추천 논문 발견 등 중요 이벤트 발생 시에 알람을 받을 수 있습니다.</li>
        <li>다이렉트 피드백 채널을 통해 필요한 기능을 요청하고 응답받을 수 있습니다.</li>
        <li>기관 연구 분석 서비스에 대한 다양한 혜택이 제공됩니다.</li>
      </ul>
      <br />
      <div>
        <div>데이터 정밀 분석에는 통상 2~3 일의 시간이 소요되며, 분석 완료 후 메일로 알려드리겠습니다.</div>
        <div>베타 서비스 기간 동안에는 정식 회원 가입이 무료로 진행됩니다.</div>
      </div>
      <div className="gotcha flex justify-center mt-8">
        <Button elementType="button" onClick={onClickSignUpBtn} size="large">
          회원 가입 신청
        </Button>
      </div>
    </div>
  );
};

export const SignUpFinishContent: FC = () => {
  const setGlobalDialogState = useSetRecoilState(globalDialogState);

  return (
    <>
      <div className="mt-8 font-light text-lg leading-relaxed">
        데이터 정밀 분석에는 통상 2~3 일의 시간이 소요되며, 분석 완료 후 메일로 알려드리겠습니다.
        <br />
        분석 기간 동안에도 샘플 데이터를 계속 확인하실 수 있습니다.
        <br />
        <br />
        Xenure 서비스 팀은 내부의 축적된 분석력을 토대로 기관 분석 서비스 또한 운영 중입니다.
        <br />
        자세한 내용은 다음 링크에서 확인하실 수 있습니다.
      </div>
      <div className="gotcha flex justify-center my-4">
        <Button
          elementType="link"
          to="/institution-analytics"
          className="mt-8"
          size="large"
          onClick={() => setGlobalDialogState(INITIAL_GLOBAL_DIALOG_STATE)}
        >
          기관 분석 알아보기
        </Button>
      </div>
    </>
  );
};

const SampleUserLockModal: FC<{ initialStep?: SampleUserLockModalStep }> = ({ initialStep }) => {
  const { user } = useCurrentUser();
  const [step, setStep] = useState<SampleUserLockModalStep>(initialStep ?? SampleUserLockModalStep.GUIDE);
  const setGlobalDialogState = useSetRecoilState(globalDialogState);
  const alreadySignedUp = user.status === 'IN_TRANSITION';

  const title =
    step === SampleUserLockModalStep.FINISH || alreadySignedUp
      ? '회원으로 가입해 주셔서 감사합니다.'
      : '정밀 분석을 위해 회원으로 가입해 주세요.';

  return (
    <ModalContent
      headline={<div className="text-3xl text-center font-bold tracking-tight px-8">{title}</div>}
      footer={
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setGlobalDialogState(INITIAL_GLOBAL_DIALOG_STATE)}
        >
          CLOSE
        </button>
      }
      wrapperStyle={{ overflow: 'auto' }}
    >
      {!alreadySignedUp && step === SampleUserLockModalStep.GUIDE && (
        <FeatureLockedContent
          onClickSignUpBtn={() => {
            setStep(SampleUserLockModalStep.FORM);
            gtm.event(EventName.SAMPLE_FORM, { context: EventContext.SAMPLE_REPORT });
          }}
        />
      )}
      {!alreadySignedUp && step === SampleUserLockModalStep.FORM && (
        <SampleUserSignUpForm onSignUp={() => setStep(SampleUserLockModalStep.FINISH)} />
      )}
      {(alreadySignedUp || step === SampleUserLockModalStep.FINISH) && <SignUpFinishContent />}
    </ModalContent>
  );
};

export default SampleUserLockModal;
