import React, { FC, FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useClickOutsideListener } from '../../hooks/useClickoutsideListener';
import { InputField } from '@pluto_network/pluto-design-elements';
import styled from '@emotion/styled';

interface DebouncedAffiliationInputProps {
  hasError: boolean;
  errorMsg?: string;
  initialAffiliation?: SelectableAffiliation;
  onBlur: () => void;
  onSelect: (affiliation: SelectableAffiliation) => void;
  onChange: (value: string) => void;
}

export interface SelectableAffiliation {
  affiliation_id: string;
  keyword: string;
  type: string;
}

const AffiliationItem = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? 'rgba(0,0,0,0.05)' : 'transparent')};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const DebouncedAffiliationInput: FC<DebouncedAffiliationInputProps> = ({
  onSelect,
  onBlur,
  hasError,
  errorMsg,
  onChange,
  initialAffiliation,
}) => {
  const { t } = useTranslation(['sign_up_page']);
  const [searchAffiliationInput, setSearchAffiliationInput] = useState(initialAffiliation?.keyword ?? '');
  const [affiliations, setAffiliations] = useState<SelectableAffiliation[]>(
    initialAffiliation ? [initialAffiliation] : []
  );
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (searchAffiliationInput.length > 0) {
        const res = await Axios.get<{
          data: { content: SelectableAffiliation[] };
        }>('https://www.scinapse.io/api/complete/affiliation', {
          params: { q: searchAffiliationInput },
        });

        setAffiliations(res.data?.data?.content || []);
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [searchAffiliationInput]);

  useClickOutsideListener({
    dom: wrapperRef.current,
    cb: (e: MouseEvent) => {
      e.stopPropagation();
      if (isOpen) {
        setIsOpen(false);
        onBlur();
      }
    },
  });

  function handleSelect(affiliation: SelectableAffiliation) {
    onSelect(affiliation);
    setSearchAffiliationInput(affiliation.keyword);
    setIsOpen(false);
  }

  return (
    <div ref={wrapperRef} className="relative">
      <InputField
        value={searchAffiliationInput}
        placeholder={t('current_affiliation')}
        leadingIcon={<i className="dripicons-graduation" />}
        error={hasError && !!errorMsg ? errorMsg : undefined}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          setSearchAffiliationInput(e.currentTarget.value);
          setCurrentIdx(-1);
          onChange(e.currentTarget.value);
          if (e.currentTarget.value.length > 1) setIsOpen(true);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          switch (e.keyCode) {
            case 13: {
              // enter
              e.preventDefault();
              handleSelect(affiliations[currentIdx]);
              break;
            }

            case 38: {
              // up
              setCurrentIdx(currentIdx - 1 < -1 ? affiliations.length - 1 : currentIdx - 1);
              break;
            }

            case 40: {
              // down
              setCurrentIdx(currentIdx + 1 > affiliations.length - 1 ? -1 : currentIdx + 1);
              break;
            }
          }
        }}
      />
      {isOpen && affiliations.length > 0 && (
        <div className="border rounded-b-md top-full overflow-y-auto h-80 left-0 right-0 bg-white absolute p-2 z-10">
          {affiliations.map((affiliation, i) => (
            <AffiliationItem
              key={affiliation.affiliation_id}
              isActive={i === currentIdx}
              style={{
                borderBottom: i === affiliations.length - 1 ? 0 : '1px solid #eee',
                cursor: 'pointer',
              }}
              onClick={() => handleSelect(affiliation)}
            >
              <div style={{ padding: '8px 16px' }}>{affiliation.keyword}</div>
            </AffiliationItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default DebouncedAffiliationInput;
