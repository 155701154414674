import React, { FC, useState, useEffect } from 'react';
import { FormikErrors, Form, Formik, Field } from 'formik';
import validateEmail from 'helpers/validateEmail';
import styled from '@emotion/styled';
import FormikInput from 'components/common/formikInput';
import { Button } from '@pluto_network/pluto-design-elements';
import palette from 'styles/palette';
import { useRecoilState } from 'recoil';
import { globalDialogState, INITIAL_GLOBAL_DIALOG_STATE } from 'atom/globalModal';
import UpdatePubSourceModal from 'components/updatePubSourceModal';
import { getPublicationSource } from 'api/auth';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { sendFeedback } from 'api/feedback';

type FeedbackFormValue = {
  email: string;
  feedbackContent: string;
};

const FormWrapper = styled(Form)`
  width: 100%;
  margin-top: 16px;
`;

const FieldWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const SucceedMessageWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  padding: 32px 0;
  border-top: 1px solid ${palette.gray400};
`;

const ButtonWrapper = styled.div`
  button + button {
    margin-top: 8px;
  }
`;

const FeedbackForm: FC = () => {
  const { t } = useTranslation(['sign_in_form', 'sign_up_page', 'general']);
  const [isSendSucceed, setIsSendSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_, setGlobalDialog] = useRecoilState(globalDialogState);

  const validateForm = (values: FeedbackFormValue) => {
    const errors: FormikErrors<FeedbackFormValue> = {};
    if (!validateEmail(values.email)) {
      errors.email = t('sign_up_page:email_validate_error');
    }

    if (!values.feedbackContent) {
      errors.feedbackContent = t('feedback_contents_validate_error');
    }

    return errors;
  };

  useEffect(() => {
    if (!isModalOpen) return;

    getPublicationSource().then((res) => {
      setGlobalDialog({
        content: (
          <UpdatePubSourceModal
            publicationSource={res}
            onToggle={() => {
              setGlobalDialog(INITIAL_GLOBAL_DIALOG_STATE);
              setIsModalOpen(false);
            }}
          />
        ),
      });
    });
  }, [isModalOpen, setGlobalDialog]);

  async function handleSubmitFeedback({ email, feedbackContent }: FeedbackFormValue) {
    setIsLoading(true);
    try {
      await sendFeedback({
        email,
        content: JSON.stringify({
          description: 'Xenure feedback : ' + feedbackContent,
          subject: 'Direct Message : ' + email,
        }),
      });

      setIsLoading(false);
      setIsSendSucceed(true);
    } catch (err) {
      const errorResponse = (err as AxiosError).response?.data.error;
      alert(errorResponse.message);
      setIsLoading(false);
    }
  }

  if (isSendSucceed) {
    return (
      <SucceedMessageWrapper>
        <div dangerouslySetInnerHTML={{ __html: t('after_send_feedback_msg') }} />
      </SucceedMessageWrapper>
    );
  }

  return (
    <Formik
      initialValues={{
        email: '',
        feedbackContent: '',
      }}
      onSubmit={handleSubmitFeedback}
      validate={validateForm}
      validateOnChange={false}
    >
      <FormWrapper>
        <FieldWrapper>
          <Field
            name="email"
            type="email"
            labelText={t('general:email')}
            component={FormikInput}
            placeholder={t('feedback_email_placeholder')}
            leadingIcon={<i className="dripicons-mail"></i>}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Field
            name="feedbackContent"
            type="textarea"
            labelText={t('feedback')}
            component={FormikInput}
            placeholder={t('feedback_placeholder')}
            style={{ height: '150px' }}
            multiline
          />
        </FieldWrapper>
        <ButtonWrapper>
          <Button size="large" type="submit" elementType="button" color="blue" fullWidth isLoading={isLoading}>
            <span>{t('send_feedback_btn')}</span>
          </Button>
          <Button
            size="large"
            type="button"
            elementType="button"
            color="gray"
            fullWidth
            isLoading={isLoading}
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            <span>{t('modify_pub_list_btn')}</span>
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Formik>
  );
};

export default FeedbackForm;
