import { useMutation, useQueryClient } from 'react-query';
import { signInDemo } from '../api/auth';
import gtm, { EventName } from '../helpers/gtm';
import { useHistory } from 'react-router-dom';

export function useLogInDemo() {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation('/auth/login', signInDemo, {
    onSuccess: (user) => {
      queryClient.setQueryData('/auth/login', user);
      history.push('/overview');
      gtm.event(EventName.LOGIN_DEMO);
    },
    onError: (err: any) => {
      const errorResponse = err.response?.data?.error?.message;
      alert(errorResponse || err);
    },
  });
}
