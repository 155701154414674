import { useEffect } from 'react';

interface Params {
  dom: HTMLElement | null;
  cb: (e: MouseEvent) => void;
}

export function useClickOutsideListener({ dom, cb }: Params) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!dom?.contains(e.target as Node)) {
        cb(e);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
}
