import { atom } from 'recoil';

type GlobalDialogState =  {
  content: JSX.Element | null;
  size?: 'sm' | 'md' | 'lg',
};

export const INITIAL_GLOBAL_DIALOG_STATE: GlobalDialogState = { content: null };

export const globalDialogState = atom<GlobalDialogState>({
  key: 'globalDialogState',
  default: INITIAL_GLOBAL_DIALOG_STATE,
});
