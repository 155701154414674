export const PRODUCTION_TRACKING_ID = 'UA-171266609-1';

export enum EventCategory {
  EXPERIMENT = 'EXPERIMENT',
}

export enum EventAction {
  CLICK_REFRESH_DATA = 'CLICK REFRESH DATA',
  CLICK_DOWNLOAD_CSV_AT_PUBLICATIONS_PAGE = 'CLICK DOWNLOAD CSV AT PUBLICATIONS PAGE',
}
