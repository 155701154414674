import React, { FC } from 'react';
import { LazyImage } from '@pluto_network/pluto-design-elements';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import palette from 'styles/palette';
import { shuffle } from 'lodash';
import { useTranslation } from 'react-i18next';

const AFFILIATIONS = [
  'postech',
  'snu',
  'kaist',
  'wisconsin',
  'harvard',
  'mit',
  'cau',
  'kist',
  'ucberkeley',
  'stanford',
  'hanyang',
  'pusan',
  'skku',
  'ajou',
  'unist',
  'sejong',
  'gist',
  'kgu',
  'kmu',
  'inha',
  'yonsei',
  'gachon',
  'chonnam',
];

const marquee1 = keyframes`
  from {
    left: 0;
  }
  to {
    left: -4242px;
  }
`;

const marquee2 = keyframes`
  from {
    left: 4242px;
  }
  to {
    left: 0;
  }
`;

const AffiliationList = styled.div`
  background-image: url(https://assets.pluto.im/scinapse-affiliation-background.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 0;
  overflow: hidden;
`;

const ContentBlockDivider = styled.div`
  width: 48px;
  height: 2px;
  margin: 24px auto;
  background-color: ${palette.main_blue0};
`;

const ListTitle = styled.div`
  text-align: center;
  line-height: 1.25;
  font-weight: 500;
  font-size: 16px;
  color: ${palette.black1};

  b {
    font-size: 20px;
  }
`;

const AffiliationImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 8500px;
  height: 100px;
  margin-top: 36px;
`;

const AffiliationImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 80px;
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 1, 64, 0.18);
  background-color: white;
  margin-left: 16px;
`;

const Marquee1Affiliation = styled.div`
  position: absolute;
  display: inline-flex;
  left: 0;
  flex-grow: 1;
  flex-shrink: 0;
  animation: ${marquee1} 50s linear;
  animation-iteration-count: infinite;
`;

const Marquee2Affiliation = styled.div`
  position: absolute;
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 0;
  animation: ${marquee2} 50s linear;
  animation-iteration-count: infinite;
`;

const AffiliationImage = styled(LazyImage)`
  max-width: 120px;
  height: 36px;
`;

const AffiliationImageList: FC = () => {
  const { t } = useTranslation('about_page');
  const shuffledAffiliation = shuffle(AFFILIATIONS);
  const affiliationList = shuffledAffiliation.map((affiliation, index) => {
    return (
      <AffiliationImageWrapper key={index}>
        <AffiliationImage
          src={`https://assets.pluto.im/affiliations/${affiliation}.png`}
          webpSrc={`https://assets.pluto.im/affiliations/${affiliation}.webp`}
          loading="lazy"
          alt={`${affiliation}LogoImage`}
        />
      </AffiliationImageWrapper>
    );
  });

  return (
    <AffiliationList>
      <ListTitle dangerouslySetInnerHTML={{ __html: t('affiliation_list_title') }} />
      <ContentBlockDivider />
      <AffiliationImageContainer>
        <Marquee1Affiliation>{affiliationList}</Marquee1Affiliation>
        <Marquee2Affiliation>{affiliationList}</Marquee2Affiliation>
      </AffiliationImageContainer>
    </AffiliationList>
  );
};

export default AffiliationImageList;
