import React, { FC } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { getCategories } from '../../api/category';

const SelectorWrapper = styled.div`
  width: 100%;

  & + & {
    margin-left: 16px;
  }
`;

const CategorySelector: FC<{ defaultOption?: { label: string; value: string }; placeholder?: string }> = ({
  defaultOption,
  placeholder,
}) => {
  const history = useHistory();
  const { data: options, error } = useQuery('/categories', getCategories);

  if (!error && !options) return <div>Loading . . .</div>;

  return (
    <SelectorWrapper>
      <Select<{ label: string; value: string; temp?: string }>
        placeholder={placeholder}
        options={options}
        defaultValue={defaultOption}
        onChange={(selectedItem) => {
          const selectedCategory = selectedItem as { label: string; value: string } | undefined;
          if (!selectedCategory) return;
          history.push(`/journal-rank/${selectedCategory.value}`);
        }}
      />
    </SelectorWrapper>
  );
};

export default CategorySelector;
