import React from 'react';
import { RecoilRoot } from 'recoil';
import Router from 'router';
import Helmet from 'components/helmet';
import '../assets/scss/Saas.scss';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

function App() {
  return (
    <RecoilRoot>
      <Helmet />
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
