import { FC, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { PRODUCTION_TRACKING_ID } from 'constants/ga';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(PRODUCTION_TRACKING_ID);
}

const LocationListener: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  return null;
};

export default LocationListener;
